<template>
  <div class="view-invoice-wrapper">
    <div class="everything-wrapper-cuz-i-forgor-smt" v-if="invoice">
      <div class="invoice-header">
        <div class="left-side">
          <div class="invoice-info">
            {{ $t("app.invoice-overview.viewInvoice.invoiceNum") }}
            <span>{{ invoice.invoiceNumber }}</span>
          </div>
          <div class="invoice-status">
            {{ $t("app.invoice-overview.viewInvoice.invoiceGood") }}
          </div>
        </div>
        <div class="right-side">
<!--  -->
          <div 
          v-if="invoice?.transactionType === 0 && invoice?.invoiceType === 4 && (!invoice?.billLocked)"
          class="btn"
          @click="complete"
          >
             {{ $t("app.invoice-overview.viewInvoice.complete") }}
          </div>
          <div 
          v-if="invoice?.transactionType === 0 && invoice?.invoiceType === 4 && (!invoice?.billLocked)"
          class="btn"
          @click="addOn"
          >
             {{ $t("app.invoice-overview.viewInvoice.addOn") }}
          </div>
<!--  -->
          <div 
          class="btn"
          @click="copyInvoice"
          >
             {{ $t("app.invoice-overview.viewInvoice.copyInvoice") }}
          </div>


          <div 
          v-if="invoice?.transactionType === 0 && invoice?.invoiceType !== 2 && (!invoice?.billLocked)"
          class="btn"
          @click="refund"
          >
             {{ $t("app.invoice-overview.viewInvoice.refund") }}
          </div>
          <div 
          v-if="invoice?.transactionType === 0 && invoice?.invoiceType !== 2 && (!invoice?.billLocked)"
          class="btn" 
          @click="storno">
             {{ $t("app.invoice-overview.viewInvoice.storno") }}
          </div>
           <router-link class="return" to="/" title="return">
             <img src="../../assets/images/icons/return.svg" alt="">
          </router-link>
          <div  
            v-if="invoice.journal"
            class="return"
            @click="idElementPrint">
            <img src="../../assets/images/icons/print.svg" alt="">
          </div>
          <div   
            v-if="invoice.journal"
            class="return" 
            >
            <!-- <a :href="'mailto: ?subject= email fiskalko &body='+encodeURIComponent(contentForEmail())"> -->
            <img src="../../assets/images/icons/sendEmail.svg" alt="">
            <!-- </a> -->
          </div>
          
          <!-- <div 
           v-if="invoice.journal"
            @click="idElementPrint"
            class="btn pdf">
            {{ $t("app.invoice-overview.viewInvoice.pdfBtn") }}
          </div> -->
          <!-- <button class="btn email">
            {{ $t("app.invoice-overview.viewInvoice.emailBtn") }}
          </button> -->
        </div>
      </div>
      <!-- ovo bi bilo dojaja kad bi moglo sa beka da ide -->
      <div class="details-wrap">
        <div class="field alt">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.pib") }}
          </div>
          <div class="field-value">
            {{ invoice.tin }}
          </div>
        </div>
        <div class="field">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.suplier") }}
          </div>
          <div class="field-value">
            {{ invoice.businessName }}
          </div>
        </div>
        <div class="field alt">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.placeOfSale") }}
          </div>
          <div class="field-value">
            {{ invoice.locationName }}
          </div>
        </div>
        <div class="field">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.address") }}
          </div>
          <div class="field-value">
            {{ invoice.address }}
          </div>
        </div>
        <div class="field alt">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.municipality") }}
          </div>
          <div class="field-value">
            {{ invoice.district }}
          </div>
        </div>
        <div class="field">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.cashier") }}
          </div>
          <div class="field-value">
            {{ invoice.cashier }}
          </div>
        </div>
        <div class="field alt">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.buyerIdentification") }}
          </div>
          <div class="field-value">
            {{ invoice.buyerId }}
          </div>
        </div>
        <div class="field">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.optionalField") }}
          </div>
          <div class="field-value">
            {{ invoice.buyerCostCenterId }}
          </div>
        </div>
        <div class="field alt">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.ESIRNum") }}
          </div>
          <div class="field-value">{{ invoice.softwareVersion }}</div>
        </div>
        <div class="field">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.ESIRTime") }}
          </div>
          <div class="field-value">
            {{ invoice.dateAndTimeOfIssue }}
          </div>
        </div>
        <div class="field alt wider">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.amountDue") }}
          </div>
          <div class="field-value">
            {{ invoice.totalAmount }}
          </div>
        </div>
        <div class="field wider">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.taxTotal") }}
          </div>
          <div class="field-value">
            {{ getTotalTaxAmount }}
          </div>
        </div>
        <div class="field alt wider">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.paymentType") }}
          </div>
          <div class="field-value">
            {{ getTransactionType }}
          </div>
        </div>
        <div class="field wider">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.invoiceType") }}
          </div>
          <div class="field-value">
            {{ getInvoiceType }}
          </div>
        </div>
        <div class="field alt wider">
          <div class="field-title">
            {{ $t("app.invoice-overview.viewInvoice.PFRTime") }}
          </div>
          <div class="field-value">
            {{ invoice.billDate }}
          </div>
        </div>
      </div>
      <div class="invoice-table">
        <div class="table-header">
          <div class="table-header-item">
            {{ $t("app.invoice-overview.viewInvoice.barcodeHeader") }}
          </div>
          <div class="table-header-item">
            {{ $t("app.invoice-overview.viewInvoice.nameHeader") }}
          </div>
          <div class="table-header-item">
            {{ $t("app.invoice-overview.viewInvoice.priceHeader") }}
          </div>
          <div class="table-header-item">
            {{ $t("app.invoice-overview.viewInvoice.amountHeader") }}
          </div>
          <div class="table-header-item">
            {{ $t("app.invoice-overview.viewInvoice.totalPriceHeader") }}
          </div>
          <div v-if="invoice.invoiceType === 4" class="table-header-item">
            {{ $t("app.invoice-overview.viewInvoice.paid") }}
          </div>
          <div v-if="invoice.invoiceType === 4" class="table-header-item">
            {{ $t("app.invoice-overview.viewInvoice.totalPaid") }}
          </div>
          
        </div>
        <div class="table-items">
          <div
            v-for="(item, index) in invoice.items"
            :key="index"
            class="single-item"
          >
            <div class="barcode" :title="item.gtin">{{ item.gtin }}</div>
            <div class="name"  :title="item.name"> {{ item.name }} </div>
            <div class="price">{{ item.unitPrice }}</div>
            <div class="amount">{{ item.quantity }}</div>
            <div class="total-price">{{ item.totalPrice }}</div>
            <div v-if="invoice.invoiceType === 4" class="total-price">{{item.amountPaid}}</div>
            <div v-if="invoice.invoiceType === 4" class="total-price">{{item.totalPaid}}</div>

          </div>
        </div>
      </div>
      <div class="tax-summary">
        <div class="tax-header">
          <div class="tax-header-item">
            {{ $t("app.invoice-overview.viewInvoice.taxRateThingHeader") }}
          </div>
          <div class="tax-header-item">
            {{ $t("app.invoice-overview.viewInvoice.nameOfTaxHeader") }}
          </div>
          <div class="tax-header-item">
            {{ $t("app.invoice-overview.viewInvoice.taxRateHeader") }}
          </div>
          <div class="tax-header-item">
            {{ $t("app.invoice-overview.viewInvoice.taxAmountHeader") }}
          </div>
        </div>
        <div
          v-for="(tax, index) in invoice.taxItems"
          :key="index"
          class="tax-value"
        >
          <div class="mark-idk">{{ tax.label }}</div>
          <div class="tax-name">{{ tax.categoryName }}</div>
          <div class="tax-rate">{{ tax.amount }}%</div>
          <div class="tax-amount">{{ tax.rate }}%</div>
        </div>
      </div>
      <div v-if="invoice.journal" id="printableArea" class="wrappInvoice" style="display:flex; justify-content: center;">
        <pre>
          {{"\r\n" +invoice.journal?.slice(0,splitBill)}}
        <img style="width:200px; height: 200px" v-if="invoice.qrCode" :src="'data:image/png;base64,'+invoice.qrCode" alt="">
          {{"\r\n" +invoice.journal?.slice(splitBill,invoice.journal.length )}}
        </pre>
        
      </div>
      
      
    </div>
    <div v-else>
      <h2>{{ $t("app.invoice-overview.viewInvoice.invoiceNotFound") }}</h2>
    </div>
    <div v-if="false" class="side-img">
      <img src="../../assets/images/view-invoice.png" alt="" />
    </div>
  </div>
</template>

<script setup>
import api from "../../api/index";
import { useRoute, useRouter } from "vue-router";
import { useClientsStore } from "@/store/clientsStore";
import { INVOICE_TYPES, TRANSACTION_TYPE } from "@/assets/js/types";
import { getObjKeyWithValue } from "@/assets/js/helpers";
// import qrcode from "qrcode"
import { defaultStore } from "@/store/index.js"
import { ModalStore } from "@/store/modalStore";

const clientsStore = useClientsStore();
const route = useRoute();
const router = useRouter()
const store = defaultStore();
const modalStore = ModalStore();

let invoice = ref();
const splitBill = ref();
const paidAdvance = ref();
watch(
      () => route.params.id,
      () => {
        if(route.params.id)
        getInvoice()
      }
    );

async function getInvoice() {
  const id = route.params.id;
  if(!clientsStore.currentClient)
  return
  const cli_id = clientsStore.currentClient.cli_id;
  let params = {
    cli_id: cli_id,
  };
  try {
    let res = await api.getSingleInvoice(id, params);
    if (res.status === 200) {
      invoice.value = res.data.data;
      if(invoice.value.journal)
        splitBill.value = invoice.value.journal.indexOf('======== КРАЈ ФИСКАЛНОГ РАЧУНА =========')
      // createBill(res.data.data)
    }
  } catch (error) {
    console.error(error);
  }
}
console.log(route.params)
getInvoice();

function setPaidAdvance(payment){
   paidAdvance.value = 0
  for(let i = 0; i < payment.length; i++) {
   paidAdvance.value += payment[i].amount
  }
  return paidAdvance.value
}


 const imageQR = ref(null);
function createBill(data){
//   let qr;
//   // if (data.qrCode){
//   //     qrcode.toDataURL(data.qrCode, (err, qrImage) => {
//   //           console.log(data.qrCode);
//   //           console.log(err)
//   //           qr = qrImage;
//   //     });
//   //   }


//     // const qrcode = new QRCode(document.getElementById('qrcode'), {
//     //   text: data.qrCode,
//     //   width: 128,
//     //   height: 128,
//     //   colorDark : '#000',
//     //   colorLight : '#fff',
//     //   correctLevel : QRCode.CorrectLevel.H
//     // }); 

//     //  let qrcode=  `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${data.qrCode} `
//       let qrcode=  `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data= `
//     // var qrcode = new QRCode("qrcode")
//     // qrcode.makeCode(data.qrCode);
//   imageQR.value= qrcode
//   console.log(qrcode)

}

const getTotalTaxAmount = computed(() =>
  invoice.value?.taxItems?.reduce((prev, next) => prev + next.amount, 0)
);

const getTransactionType = computed(() => {
  return getObjKeyWithValue(TRANSACTION_TYPE, invoice?.value.transactionType);
});
const getInvoiceType = computed(() => {
  return getObjKeyWithValue(INVOICE_TYPES, invoice?.value.invoiceType);
});

function printDiv(divName) {
  console.log('jjjj')
     var printContents = document.getElementById("printableArea").innerHTML;
     var originalContents = document.body.innerHTML;

     document.body.innerHTML =  "<html><head><title></title></head><body>" + printContents + "</body>";
     window.print();

     document.body.innerHTML = originalContents;
}

const idElementPrint = () => {
    // const iframe = document.querySelector('iframe');
    const iframe = document.getElementById('print')
    if (iframe) {
      console.log(iframe)
      iframe.parentNode.removeChild(iframe);
      // setovati 
    }

    let textContent = document.getElementById('printableArea').innerHTML
    let ifram = document.createElement("iframe");
    ifram.style = "display:none";
    ifram.setAttribute("id", "print")
    document.body.appendChild(ifram);
   
    let pri = ifram.contentWindow;
    pri.document.open();
    pri.document.write(textContent);
    pri.document.close();
    pri.focus();
    pri.print();
    }
  function contentForEmail(){
    //  let textContent = document.getElementById('printableArea').innerHTML

    let textContent = `
      <pre>
            "\r\n" ${invoice.value.journal?.slice(0,splitBill.value)}
          <img style="width:200px; height: 200px" v-if="invoice.qrCode" :src="'data:image/png;base64,'+invoice.qrCode" alt="">
            "\r\n" ${invoice.value.journal?.slice(splitBill.value ,invoice.value.journal.length )}
      </pre>
       
    `
    console.log(textContent)
    return invoice.value.journal ? textContent : ''
  }

function copyInvoice (){
  store.setInvoiceForAction(invoice.value.billId)
  store.setInvoiceAction("COPY")
  router.push("/invoice-issuing")
}
function refund (){
  store.setInvoiceForAction(invoice.value.billId)
  store.setInvoiceAction("refund-NORMAL")
  router.push("/invoice-issuing")
  // console.log('refund')
}
function complete(){
  console.log("complete")
  store.setInvoiceForAction(invoice.value.billId)
  store.setInvoiceAction("complete-ADVANCE")
  router.push("/invoice-issuing")

}
function addOn(){
  console.log("addOn")
  store.setInvoiceForAction(invoice.value.billId)
  store.setInvoiceAction("addOn-ADVANCE")
  router.push("/invoice-issuing")
}

function storno (){
  const data = {
    component: "confirmModal",
    title: "app.invoice-overview.viewInvoice.storno",
    options: {
      confirm: () => {
        confirmStorno()
      },
      btnName: "app.invoice-overview.viewInvoice.storno",
      text: "app.invoice-overview.viewInvoice.qStorno"
    }
  };
  modalStore.setModalData(data);

}

async function confirmStorno (){
  // pozvati api kada ga dobijem 
  // console.log('storno')

  if(!clientsStore.currentClient)
    return
  let params = {
    cli_id: clientsStore.currentClient.cli_id,
    id: invoice.value.billId
  };

 try {
  let res = await api.stornoInvoice(params);
   console.log(res);
   // baciti na listu racuna
   router.push("/");
  } catch (e) {
  console.log(e)
 }

}


onMounted( () => {
});
</script>

<style lang="scss" scoped>
.view-invoice-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .everything-wrapper-cuz-i-forgor-smt {
    width: 1100px;
    width: 100%;
    .invoice-header {
      width: 100%;
      margin-bottom: 30px;
      padding-left: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left-side {
        width: 380px;
        display: flex;
        justify-content: space-between;
        span {
          color: $main-green;
        }
      }
      .right-side {
        width: fit-content;
        display: flex;
        justify-content: space-between;
        // justify-content: stretch;
        flex-wrap: wrap;

        div{
          margin-left: 10px;
          cursor: pointer;
        }

        .btn{
          margin-left: 10px;
          text-transform: capitalize;
          font-size: 0.9em;
          padding: 0 10px;
          height: 30px;
          margin-top: 10px;
        }

        .pdf,
        .email {
          min-width: 150px;
          height: $circle-btn-mobile-size;
        }
        .return{
          // width: 40px;
          // height: 40px;
          width: 30px;
          height: 30px;
          text-decoration: none;
          // padding: 0;
          cursor: pointer;
          margin-left: 10px;
          margin-top: 10px;
          img{
            width: 100%;
            height: auto;
           
          }
        }

      }
    }

    .details-wrap {
      max-height: 510px;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      // justify-content: space-between;
      align-content: space-between;
      .field {
        // width: 512px;
        width: calc(50% - 20px);
        min-height: 50px;
        border-radius: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        &.alt {
          background-color: $main-gray;
        }
        &.wider {
          // width: 535px;
          margin-left: 30px;
        }
        .field-title {
          font-size: 15px;
          font-weight: 400;
          color: $main-dark;
        }
        .field-value {
          font-size: 15px;
          font-weight: 700;
          color: $main-dark;
          text-align: right;
        }
      }
    }

    .invoice-table {
      .table-header {
        width: 100%;
        padding: 0 30px;
        margin-bottom: 3px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .table-header-item {
          width: 20%;
          font-size: 11px;
          font-weight: 700;
          text-transform: uppercase;
          text-align: right;
          color: #acacad;
          &:first-child {
            text-align: left;
          }
        }
      }
      .table-items {
        .single-item {
          width: 100%;
          height: 50px;
          padding: 0 30px;
          background-color: $main-gray;
          border-radius: 50px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            width: 20%;
            text-align: right;
            font-size: 15px;
            font-weight: 500;
            color: $main-dark;
            &:first-child {
              text-align: left;
            }
          }
          .name, .barcode{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .tax-summary {
      .tax-header {
        width: 100%;
        padding: 0 30px;
        margin-top: 20px;
        margin-bottom: 3px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .tax-header-item {
          width: 25%;
          font-size: 11px;
          font-weight: 700;
          text-transform: uppercase;
          text-align: right;
          color: #acacad;
          &:first-child {
            text-align: left;
          }
        }
      }
      .tax-value {
        width: 100%;
        height: 50px;
        padding: 0 30px;
        background-color: $main-gray;
        border-radius: 50px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          width: 25%;
          text-align: right;
          font-size: 15px;
          font-weight: 500;
          color: $main-dark;
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }

  .side-img {
    width: 265px;
    height: 265px;
    padding-top: 100px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.wrappInvoice{
  display: flex;
  justify-content: center;
  // pre{
  //   margin: auto;
  // }
 }
@media only screen and (max-width: 800px) {
  .view-invoice-wrapper {
    .everything-wrapper-cuz-i-forgor-smt {
      width: 100%;
      .invoice-header {
        flex-direction: column;
        padding-left: 0;
        .left-side {
          flex-direction: column;
        }
        .right-side {
          // flex-direction: column;
          width:100%;
          margin-top: 20px;
          justify-content: flex-end;

          .pdf {
            margin-top: 14px;
            margin-bottom: 8px;
          }
        }
      }

      .details-wrap {
        max-height: unset;

        .field {
          width: 100%;
          padding: 0 23px;
          &.wider {
            width: 100%;
            margin-left: 0;
          }
        }
      }

      .invoice-table {
        .table-header {
          align-items: center;
          justify-content: space-evenly;
          padding: 0 23px;
        }
        .table-items {
          .single-item {
            padding: 0 23px;
          }
        }
      }
      .tax-summary {
        .tax-header {
          align-items: center;
        }
      }
    }
  }
}
@media only screen and (max-width: 400px) {
.view-invoice-wrapper {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // margin-bottom: 30px;
  .everything-wrapper-cuz-i-forgor-smt {
    .invoice-header {
      .left-side {
        
      }
      .right-side {
        // width: fit-content;
        // display: flex;
        // justify-content: space-between;

        div{
          margin-left: 10px;
          cursor: pointer;
        }

        .btn{
          margin-left: 10px;
        }

        .pdf,
        .email {
          min-width: 150px;
          height: $circle-btn-mobile-size;
        }
      }
    }

    .details-wrap {
      .field {
        padding: 0 10px;
        &.alt {
          background-color: $main-gray;
        }
        &.wider {
          // width: 535px;
          // margin-left: 30px;
        }
        .field-title {
          font-size: 12px;
          font-weight: 400;
        }
        .field-value {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }

    .invoice-table {
      .table-header {
         padding: 0 10px;
        .table-header-item {
          font-size: 10px;
          font-weight: 600;
          text-align: left;
          &:first-child {
            text-align: left;
          }
        }
      }
      .table-items {
        
        .single-item {
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // flex-direction: column;
          // height: fit-content;
          div {
            padding-right: 3px;
            font-size: 12px;
            font-weight: 500;
            word-break: break-all;
            text-align: left;
            &:first-child {
              text-align: left;
            }
          }
          div:last-of-type{
            padding-right: 0px;
          }
          // .name {
          //   overflow: hidden;
          //   text-overflow: ellipsis;
          //   white-space: nowrap;
          // }
        }
      }
    }
    .tax-summary {
      .tax-header {
         padding: 0 10px;
        .tax-header-item {
          font-size: 10px;
          font-weight: 600;
          text-align: left;
          padding-right: 3px;
          &:first-child {
            text-align: left;
          }
        }
        .tax-header-item:last-of-type{
          padding-right: 0px;
        }
      }
      .tax-value {
        padding: 0 10px;
        div {
          font-size: 12px;
          font-weight: 500;
           text-align: left;
          &:first-child {
            // text-align: left;
          }
        }
      }
    }
  }
}

.wrappInvoice{
  display: flex;
  justify-content: center;
  // pre{
  //   margin: auto;
  // }
  scale: 0.6;
  margin-top: -120px;
 }
}
</style>
