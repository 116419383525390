<template>
  <div
    v-if="store.getModalData"
    ref="modal_wrap"
    :class="{ transparent: store.getModalData.options?.hideBackdrop }"
    class="backdrop"
  >
    <div
      :class="{ transparent: store.getModalData.options?.transparentModal }"
      class="modal-wrapper"
      :style="store.getModalData.options?.styleParent ? store.getModalData.options.styleParent: ''"
    >
      <div class="modal-header">
        <h2>{{ store.getModalData.title && store.getModalData.title !== '' ? $t(store.getModalData.title) : ''}}</h2>
        <img
          v-if="!store.getModalData.options?.hideClose"
          src="../../assets/images/icons/x.png"
          alt=""
          @click="store.setModalData(null)"
        />
      </div>
      <div class="modal-content"
       :style="store.getModalData.options?.styleContent? store.getModalData.options.styleContent: ''"
       v-click-outside="handleClickOutsideModal">
        <component
          :is="modalsMap[store.getModalData.component]"
          :options="store.getModalData.options"
          @close="store.setModalData(null)"
        >
        </component>
      </div>
    </div>
  </div>
</template>

<script setup>
import addApiKey from "./modals/addApiKey.vue";
import ConfirmCreatingCertificates from "./modals/ConfirmCreatingCertificates.vue";
import deleteApiKey from "./modals/deleteApiKey.vue";
import confirmModal from "./modals/confirmModal.vue";
import filterModal from "./modals/filterModal.vue";
import invoiceModal from "./modals/invoiceModal.vue";
import { ModalStore } from "@/store/modalStore";

const store = ModalStore();

const handleClickOutsideModal = (event) => {
  store.setModalData(null);
};

const modalsMap = {
  addApiKey,
  deleteApiKey,
  ConfirmCreatingCertificates,
  confirmModal,
  filterModal,
  invoiceModal
};
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba($color: rgb(255, 255, 255), $alpha: 60%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .modal-wrapper {
    // background: #1b1b1e;
    // border: 2px solid #242427;
    // border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    min-width: 385px;
    width: fit-content;
    max-width: 100%;

    // overflow: hidden;

    .modal-header {
      // height: 69px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      padding-left: 30px;
      padding-right: 30px;
      padding: 20px 40px 20px 45px;
      // background-image: url("../assets/images/modalHeaderBackground.png");
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: cover;

      img {
        height: 15px;
        cursor: pointer;
        transition: all 0.5s;
      }

      img:hover {
        filter: invert(100%);
        transition: all 0.5s;
      }

      h2 {
        // font-weight: 700;
        // font-size: 16px;
        // line-height: 17px;
        // color: #ecedf2;
        font-weight: 700;
        font-size: 16px;
        line-height: 17px;
        max-width: 50%;
      }

      h2::first-letter {
        text-transform: capitalize;
      }
    }

    .modal-content {
      padding: 10px 40px 17px 45px;
      // padding-top: 22px;
      // padding-top: 10px;
      // padding-bottom: 22px;
      box-sizing: border-box;
        width: fit-content;
    }
  }

  .modal-wrapper.transparent {
    // background: rgba(27 27 30 / 85%);
    background: linear-gradient(
      114.92deg,
      #0c0c10 0%,
      #191922 51.56%,
      #0c0c10 100%
    );
    border: 1px solid #1f2330;
    box-shadow: 0px 0px 40px rgba(118, 148, 255, 0.05);
    border-radius: 10px;
  }
}

.backdrop.transparent {
  background: transparent;
}
</style>
