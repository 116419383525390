import api from "../api";
import { defineStore } from "pinia";
import { useClientsStore } from "./clientsStore";
// const clientsStore = useClientsStore();

export const useTaxStore = defineStore("Tax", {
    state: () => ({
        clientsStore: useClientsStore(),
        specialTax: null,
        ordinaryTax: null
    }),
    getters: {
        getSpecialTax() {
            return this.specialTax;
        },
        getOrdinaryTax() {
            return this.ordinaryTax;
        }

    },
    actions: {
        async setSpecialTax() {
            if (!this.clientsStore.currentClient) return;

            try {
                let params = {
                    cli_id: this.clientsStore.currentClient.cli_id,
                    special: "advance"
                };
                let res = await api.getTaxLabels(params);
                if (res.status === 200) {
                    this.specialTax = res.data.data;
                    console.log(this.specialTax)
                }
            } catch (error) {
                console.error(error);
            }
        },
        async setOrdinaryTax() {
            if (!this.clientsStore.currentClient) return;

            try {
                let params = {
                    cli_id: this.clientsStore.currentClient.cli_id,
                };
                let res = await api.getTaxLabels(params);
                if (res.status === 200) {
                    this.ordinaryTax = res.data.data;
                    console.log(this.ordinaryTax)
                }
            } catch (error) {
                console.error(error);
            }
        }

    },

});