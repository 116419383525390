<template>
  <div ref="wrapper" class="dropdown-one-wrapper">
    <div v-if="label" class="label">
      {{ label }}
    </div>
    <div class="input-wrapper" :class="{ disabled: disabled }">
      <input
        ref="input"
        @focus="dropdown = true"
        :value="
          translate && modelValue 
          ? (dropdownLabel ? $t('app.option.'+modelValue[dropdownLabel]) : $t('app.option.'+modelValue))
          : (dropdownLabel && modelValue ? modelValue[dropdownLabel] : modelValue)"
        type="text"
        @input="updateValue"
        :placeholder="placeholder"
        :disabled="disabled || translate"
      />
      <div @click="dropdown = !dropdown" class="icon-wrapper">
        <img v-if="icon" :class="{ active: dropdown }" :src="icon" alt="" />
        <img v-else :class="{ active: dropdown }" :src="arrow" alt="" />
      </div>
    </div>
    <div
      v-if="getOptions.length > 0 && dropdown"
      :class="{ active: dropdown }"
      class="dropdown-wrapper box"
    >
      <div class="scrollbar custom-scollbar">
        <div
          v-for="(item, index) in getOptions"
          :key="index"
          class="dropdown-item"
          @click="selectOption(item)"
        >
          {{ item[dropdownLabel] !== undefined 
          ? (translate ? $t('app.option.'+item[dropdownLabel] ) :  item[dropdownLabel])
          : (translate ? $t('app.option.'+item) : item)
          }}
        </div>
      </div>
    </div>
     <label
      v-if="notification"
      :class="{ shakeWarn: isShaking }"
      :style="{ color: notifColor }"
      class="notif-label"
    >
      {{ notification }}
    </label>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import arrow from "./arrow.png";
export default {
  name: "DropdownOne",
  props: {
    modelValue: {
      required: false,
      default: ""
    },
    options: {
      type: Array,
      required: false
    },
    dropdownLabel: {
      required: false
    },
    placeholder: {
      required: false,
      default: ""
    },
    label: {
      required: false
    },
    icon: {
      required: false
    },
    disabled: {
      required: false,
      default: false
    },
    translate: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: Boolean,
      required: false,
      default: false
    },
    notification: {
      type: String,
      required: false,
      default: null,
    },
    notifColor: {
      type: String,
      required: false,
      default: "red",
    },
  },
  emits: ["update:modelValue", "optionSelected"],
  setup(props, { emit }) {
    const dropdown = ref(false);
    const input = ref("");
    const wrapper = ref(null);
    function handleClick(event) {
      var isClickInsideWrapper = wrapper.value.contains(event.target);
      if (!isClickInsideWrapper) {
        dropdown.value = false;
      }
    }
    onMounted(() => {
      document.addEventListener("click", handleClick);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClick);
    });
    function updateValue(e) {
      emit("update:modelValue", e.target.value);
    }
    const getOptions = computed(() => {
      let res = [];
      if (props.modelValue && props.search) {
        if (props.dropdownLabel) {
          res = props.options.filter(item => {
            return props.modelValue
              .toLowerCase()
              .split(" ")
              .every(v => item[props.dropdownLabel].toLowerCase().includes(v));
          });
        } else {
          res = props.options.filter(item => {
            return e.target.value
              .toLowerCase()
              .split(" ")
              .every(v => item.toLowerCase().includes(v));
          });
        }
      } else {
        res = props.options;
      }
      return res;
    });
    function selectOption(option) {
      let res =
        option[props.dropdownLabel] !== undefined
          ? option[props.dropdownLabel]
          : option;
      emit("update:modelValue", res);
      emit("optionSelected", option);
      dropdown.value = false
    }

// style functionality
    const isShaking = ref(false);
    const handleShake = () => {
      isShaking.value = true;
      setTimeout(() => {
        isShaking.value = false;
      }, 1500);
    };
    watch(
      () => props.notification,
      () => {
        handleShake();
      }
    );

    return {
      wrapper,
      input,
      updateValue,
      dropdown,
      getOptions,
      arrow,
      selectOption,
      isShaking
    };
  }
};
</script>

<style lang="scss" scoped>
// @import "../showcase-project-vue3/src/assets/scss/scroll.scss";
.dropdown-one-wrapper {
  // max-width: 410px;
  width: 100%;
  // max-width: 546px;
  position: relative;
  // width: 410px;

  .label {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #98a0b6;;
  }

  .input-wrapper {
    height: 40px;
    display: flex;
    flex-direction: row;
    // border: 1px solid #dfdfe8;
    box-sizing: border-box;
    // border-radius: 11px;
    // box-shadow: 0px 1px 3px #dfdfe8;
    border-radius: 20px !important;
    border: 1px solid $input-border-idle;
    overflow: hidden;
   

    .icon-wrapper {
      width: 60px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        transition-duration: 0.1s;
      }
      img.active {
        transform: rotate(180deg);
      }
    }
    input {
      width: calc(100% - 60px );
      outline: none;
      padding-left: 22px;
      border: none;
      border-radius: 11px;
      flex-grow: 1;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: $medium-gray;
    }
    input:disabled {
      background: transparent;
    }
    input::placeholder{
      color: #acacad;
      font-weight: 400;
      font-size: $placeholder-size;
      line-height: 14px;
    }
  }
  .input-wrapper.disabled {
    cursor: default;
    pointer-events: none;
    user-select: none;
  }
  .dropdown-wrapper {
    position: absolute;
    width: 100%;
    border: none;
    box-sizing: border-box;
    border-radius: 11px;
    height: 0px;
    transition: 0.1s;
    opacity: 0;
    padding-right: 5px;
    padding: 0;
    background: white;
    z-index: 2;

    .scrollbar {
      overflow: auto;
      max-height: 152px;
    }
  }
  .dropdown-item {
    height: 38px;
    line-height: 38px;
    cursor: pointer;
    padding: 0 14px;
    box-sizing: border-box;
    border-radius: 7px;
    transition-duration: 0.1s;
    // margin-right: 5px;
  }
  .dropdown-item:hover {
    background: $main-green;
    border-radius: 20px !important;
    color: #fff;
  }
  .active.dropdown-wrapper {
    height: auto;
    border: 1px solid #dfdfe8;
    opacity: 1;
    margin-top: 4px;
    box-shadow: 0px 1px 3px #dfdfe8;
    // padding: 8px;
  }
}
.disabled {
  background-color: #fcfafa;
}
</style>
