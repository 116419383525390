<template>
  <div class="ccc-wrapper">
    <div
      class="confirm-certificate-content"
      v-for="(item, index) in certificates"
      :key="index"
    >
      <div 
      :style="{color: item.error? 'red': ''}"
      class="file-name"
      >{{ item.file.name }}</div>
      <div class="info-wrap">
        <div class="cert-name">
          <BaseInput
            class="cert-input-name cert-confirm-input"
            :label="$t('app.compnay.certificateName')"
            v-model="item.cert_name"
          ></BaseInput>
        </div>
        <div class="cert-pass">
          <BaseInput
            class="cert-input-pass cert-confirm-input"
            :label="$t('app.compnay.certificatePass')"
            v-model="item.pass"
            :password="true"
          ></BaseInput>
          <!-- :notification="item.error ? $t(item.error) : '' " -->
        </div>
      </div>
    </div>
    <div class="confirm-btn">
      <button class="btn" @click="saveCertificate">Sacuvaj</button>
    </div>

    <div :class="{ shakeWarn: isShaking }" class="notif-label">
            {{ globalMsg === "" ? "" : $t(globalMsg) }}
    </div>
  </div>
</template>

<script setup>
import { useClientsStore } from "@/store/clientsStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api";

const clientsStore = useClientsStore();
const modalStore = ModalStore();
const certificates = reactive([
  ...modalStore.modalData.modalData.map((el) => {
    return { file: el, name: ref(""), pass: ref(""), error: ref(null)};
  }),
]);

const globalMsg = ref("");

async function saveCertificate() {
  resetError();
  let fd = new FormData();
  try {
    certificates.forEach((el) => {
      fd.append("certificate_files", el.file);
      fd.append(
        "certificate_info",
        JSON.stringify({ name: el.cert_name, pass: el.pass })
      );
    });
    fd.append("cli_id", clientsStore.currentClient?.cli_id);
    // const params = {
    //   cli_id: clientsStore.currentClient.cli_id,
    //   certificate_files: fd,
    // };
    const res = await api.createCertificate(fd);
    console.log(res.data);
    if(res.data && res.data.failed?.length > 0) {
      setError(res.data.failed)
    } else {
      props.options.update();
      modalStore.setModalData(null);
    }
  } catch (error) {
    console.log(error);
    console.log(error.message)
    console.log(error.response?.request);
    if(error.message === 'Network Error') {
      globalMsg.value= 'app.main.tooBigSize'
    }
    fd = new FormData();
  }
}

function setError(arrErr){
  for(let i=0; i < certificates.length; i++) {
    for( let j=0; j < arrErr.length; j++) {
      if (i === arrErr[j].index 
      && (certificates[i].file.name === arrErr[j].name)){
        certificates[i].error = 'app.main.error'
        globalMsg.value = 'app.main.errCertify'
      }
    }

  }
}
const props = defineProps(["options"]);

const resetError = () =>{
    certificates.forEach((el) => {
       el.error = ''
    });
    globalMsg.value = ''
}

//style
let isShaking = ref(false);
const handleShake = () => {
  isShaking.value = true;
  setTimeout(() => {
    isShaking.value = false;
  }, 1500);
};

watch(
  ()=> globalMsg.value,
  ()=> {
    handleShake();
  }
)

</script>

<style lang="scss" scoped>
.ccc-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 30px;
  .confirm-certificate-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .info-wrap {
      display: flex;
      justify-content: flex-end;
    }
    .file-name {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 134%;
      color: #344368;
    }
    .cert-name {
      .cert-confirm-input {
        margin: 0 0 25px 0;
      }
    }
    .cert-pass {
      .cert-confirm-input {
        margin: 0 0 25px 0;
      }
    }
  }
  .confirm-btn {
    display: flex;
    justify-content: end;
  }
}
.notif-label {
  min-width: 150px;
  height: 20px;
  text-align: right;
  position: absolute;
  right: 10px;
  top: auto;
  bottom: 0px !important;
  font-size: 13px;
  font-style: italic;
  color: red;
}
</style>
