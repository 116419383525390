<template>
  <div class="input-wrap datepicker-wrap"
  :class="{disabled: disabled}">
    <span v-if="type=== 'datetime-local'" class="datepicker-toggle"> 
      {{ modelValue ? formatDateTime(modelValue) : props.placeholder }}
    </span>
    <span v-else class="datepicker-toggle"> 
      {{ dateVal ? dateVal.split("-").reverse().join(".") : props.placeholder }}
    </span>
    <input
      :type="type"
      class="datepicker-input"
      :class="{disabled: disabled}"
      :value="modelValue"
      @input="sendDate($event)"
      :min="min"
      :max="max"
      :disabled="disabled"
    />
    <label
      v-if="props.notification"
      :class="{ shakeWarn: isShaking }"
      :style="{ color: props.notifColor }"
      class="notif-label"
    >
      {{ props.notification }}
    </label>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";

let dateVal = ref("");

const sendDate = (e) => {
  dateVal.value= e.target.value
  emit("input", e.target.value);
  emit("update:modelValue", e.target.value);
};

const emit = defineEmits(["input","update:modelValue"]);

const props = defineProps({
   modelValue: { 
    required: false,
    default: "",
  },
  placeholder: {
    type: String,
    required: true,
  },
   min: {
    type: String,
    required: false,
  },
   max: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
    default: 'date'
  },
   notification: {
    type: String,
    required: false,
    default: null,
  },
  disabled: {
    type:Boolean,
    required: false,
    default: false
  }
});

function formatDateTime(dateTime){
  let help = dateTime.split('T')
  return help[0].split("-").reverse().join(".") + ' ' + help[1]
}

// STYLE ERROR

let isShaking = ref(false);
const handleShake = () => {
  isShaking.value = true;
  setTimeout(() => {
    isShaking.value = false;
  }, 1500);
};
watch(
  () => props.notification,
  () => {
    handleShake();
  }
);

</script>

<style lang="scss" scoped>
.datepicker-wrap {
  display: inline-block;
  position: relative;
  width: 103px;
  height: 38px;
  border: 1px solid #dddfe4;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

   
  .notif-label {
    text-align: right;
    position: absolute;
    right: 10px;
    top: 38px;
    font-size: 13px;
    font-style: italic;
    color: red;
  }

  span {
    color: #acacad;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .datepicker-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
   
  }
}
.disabled {
    background-color: #fcfafa;
}
</style>
