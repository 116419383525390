import { default as axios } from "./config.js";

export default {
  // Auth
  registerApi: (params) => axios.post("/v1/client/auth/register", params),
  loginApi: (params) => axios.post("/v1/client/auth/login", params),
  checkSession: () => axios.get(`/v1/client/auth/check-session`),
  logoutApi: () => axios.get("/v1/client/auth/login"),
  verifyAccountApi: (params) => axios.get("/v1/client/auth/verify", { params }),

  //clients
  getClients: () => axios.get("/v1/client/user/clients"),
  getSingleCompany: (params) =>
    axios.get("/v1/client/client/profile", { params }),
  changeCompany: (params) => axios.put("/v1/client/client/profile", params),

  getTaxLabels: (params) => axios.get("/v1/client/client/labels", { params }),
  getAvansTaxLabels: (params) => axios.get("/v1/client/client/advance/special-articles", { params }),
  // Certificates
  getCertificates: (params) =>
    axios.get("/v1/client/client/certificates", { params }),
  setDefaultCertificates: (params) =>
    axios.put(`v1/client/client/certificates/${params.id}`, params),
  createCertificate: (params) =>
    axios.post("/v1/client/client/certificates", params, { headers: { "Content-Type": 'multipart/form-data' } }),
  deleteCertificate: (params) =>
    axios.delete(`/v1/client/client/certificates/${params.id}`, { params }),
  // Articles
  getArticles: (params) => axios.get("/v1/client/client/articles", { params }),
  createArticle: (params) => axios.post("/v1/client/client/articles", params),
  editArticle: (params) =>
    axios.put(`/v1/client/client/articles/${params.id}`, params),
  deleteArticle: (params) =>
    axios.delete(`/v1/client/client/articles/${params.id}`, { params }),
  searchArticles: (params) =>
    axios.get("/v1/client/client/articles/search", { params }),

  //invoices
  getAllInvoices: (params) =>
    axios.get("/v1/client/client/invoices", { params }),
  getSingleInvoice: (id, params) =>
    axios.get("/v1/client/client/invoices/" + id, { params }),
  createInvoice: (params) => axios.post("/v1/client/client/normal/invoice", params),
  stornoInvoice: (params) => axios.post(`/v1/client/client/normal/invoice/${params.id}/undo`, params),
  createRefund: (params) => axios.post("/v1/client/client/normal/refund", params),
  createTrainingRefund: (params) => axios.post("/v1/client/client/training/refund", params),
  createTrainingInvoice: (params) => axios.post("/v1/client/client/training/invoice", params),
  createAdvanceInvoice: (params) => axios.post("/v1/client/client/advance/invoice", params),
  createAdvanceSaleAdd: (id, params) => axios.post(`/v1/client/client/advance/invoice/${id}`, params),
  createAdvanceSaleComplete: (id, params) => axios.post(`/v1/client/client/advance/invoice/${id}/finalize`, params),
  createAdvanceRefund: (params) => axios.post("/v1/client/client/advance/refund", params),
  createCopySale: (params) => axios.post("/v1/client/client/copy/invoice", params),
  createCopyRefund: (params) => axios.post("/v1/client/client/copy/refund", params),
  createProformaRefund: (params) => axios.post("/v1/client/client/proforma/refund", params),
  createProformaSale: (params) => axios.post("/v1/client/client/proforma/invoice", params),

  // Profiles
  getProfile: (params) => axios.get("/v1/client/user/profile", { params }),
  editProfile: (params) => axios.put("/v1/client/user/profile", params),
  //api key
  getKey: (params) => axios.get("/v1/client/client/api-keys", { params }),
  setKey: (params) => axios.post("/v1/client/client/api-keys", params),
  deleteKey: (params) => axios.delete(`/v1/client/client/api-keys/${params.apk_id}`, { params }),
  // Company
  createCompany: (params) => axios.post("v1/client/client", params),
};
