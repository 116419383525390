import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import AuthPage from "../views/Auth/AuthPage.vue";
import LoginPage from "../views/Auth/Sub/LoginPage.vue";
import RegisterPage from "../views/Auth/Sub/RegisterPage.vue";
import ForgorPage from "../views/Auth/Sub/ForgorPage.vue";
import InvoiceOverview from "../components/viewComponents/InvoiceOverview.vue";
import SingleInvoice from "../components/viewComponents/SingleInvoice.vue";
import CreateInvoice from "../components/viewComponents/CreateInvoice.vue";
import ItemOverview from "../components/viewComponents/ItemOverview.vue";
import CreateItem from "../components/viewComponents/CreateItem.vue";
import EditItem from "../components/viewComponents/EditItem.vue";
import Profile from "../components/viewComponents/Profile.vue";
import Companies from "../components/viewComponents/Companies.vue";
import SingleCompany from "../components/viewComponents/SingleCompany.vue";
import InvoiceIssuing from "../components/viewComponents/InvoiceIssuing.vue";
import TechnicalDocs from "../components/viewComponents/TechnicalDocs.vue";
import PageNotFound from "../views/PageNotFound.vue";
import VerifyEmail from "../views/VerifyEmailPage.vue";
import apiKey from "../components/viewComponents/apiKey.vue"
import ResetPassword from "../views/Auth/Sub/ResetPassword.vue";
import { useUserStore } from "@/store/userStore";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "InvoiceOverview",
        component: InvoiceOverview,
        meta: { requiresAuth: true },
        children: [
          {
            path: "/view-invoice/:id",
            name: "ViewInvoice",
            component: SingleInvoice,
            meta: { requiresAuth: true },
          },
          // {
          //   path: "/create-invoice/",
          //   name: "CreateInvoice",
          //   component: CreateInvoice,
          // }
        ],
      },
      {
        path: "item-overview",
        name: "ItemOverview",
        component: ItemOverview,
        meta: { requiresAuth: true },
        children: [
          {
            path: "create-item",
            name: "CreateItem",
            component: CreateItem,
            meta: { requiresAuth: true },
          },
          {
            // path: "edit-item",
            path: "edit-item/:id",
            name: "EditItem",
            component: EditItem,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "apiKey",
        name: "apiKey",
        component: apiKey,
        meta: { requiresAuth: true },
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: { requiresAuth: true },
      },
      {
        path: "companies",
        name: "Companies",
        component: Companies,
        meta: { requiresAuth: true },
      },
      {
        path: "single-company",
        name: "SingleCompany",
        component: SingleCompany,
        meta: { requiresAuth: true },
      },
      {
        path: "invoice-issuing",
        name: "CreateInvoice",
        component: CreateInvoice,
        meta: { requiresAuth: true },
      },
      {
        path: "technical-docs",
        name: "TechnicalDocs",
        component: TechnicalDocs,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/auth",
    name: "AuthPage",
    component: AuthPage,
    meta: { requiresAuth: false },
    children: [
      {
        path: "login",
        name: "LoginPage",
        component: LoginPage,
        meta: { requiresAuth: false },
      },
      {
        path: "register",
        name: "RegisterPage",
        component: RegisterPage,
        meta: { requiresAuth: false },
      },
      {
        path: "forgot-password",
        name: "ForgotPage",
        component: ForgorPage,
        meta: { requiresAuth: false },
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: ResetPassword,
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: PageNotFound,
    meta: { requiresAuth: false },
  },
  {
    path: "/verify",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const userStore = useUserStore();
  await userStore.checkSession();
  if (!userStore.isLoggedIn && to.meta.requiresAuth) {
    router.push("/auth/login");
  }
});

export default router;
