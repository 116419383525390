<template>
  <div class="search-wrap">
    <span class="material-symbols-rounded"> search </span>
    <input
      class="search-input"
      type="text"
      :placeholder="props.placeholder"
      v-model="searchVal"
      @keyup="keyUp"
    />
  </div>
</template>

<script setup>
// import { defineEmits, defineProps } from "vue";

let searchVal = ref("");

const sendSearch = () => {
  emit("search", searchVal.value);
};



const emit = defineEmits(["search"]);

const props = defineProps({
  placeholder: {
    type: String,
    required: true,
  },
});
const date = ref()

function keyUp(){
    date.value=Date.now()

    setTimeout(() => {
      if(date.value +1000 <= Date.now()){
        // console.log(searchVal.value)
       emit("search", searchVal.value);
      //  console.log('yes')
      }else{
         console.log('no')
      }
      
    }, 1000);
  }
</script>

<style lang="scss" scoped>
.search-wrap {
  width: 100%;
  max-width: 254px;
  height: 38px;
  .material-symbols-rounded {
    position: absolute;
    top: 12px;
    left: 28px;
    z-index: 10;
    color: #acacad;
    font-size: 15px;
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  }

  .search-input {
    width: 100%;
    height: 100%;
    border: 1px solid $input-border-idle;
    border-radius: 50px;
    padding: 0 28px 0 48px;
    font-weight: 600;
    font-size: 12px;
    color: $main-dark;
    line-height: 14px;
    // font-family: 'Archivo', sans-serif;

    outline: none;

    &:focus {
      outline: 2px solid $main-green;
    }

    &::placeholder {
      color: #acacad;
      font-weight: 400;
      font-size: $placeholder-size;
      line-height: 14px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .search-wrap {
    width: 212px;

    .search-input {
      padding: 0 28px 0 48px;
    }
  }
}
</style>
