<template>
  <div v-if="!isBurger" class="sidebar-wrap">
    <!-- ik this is stupid but brian no work fix later -->

    <div class="split">
      <div class="title">
        {{ $t("app.sidebar.titleOne") }}
      </div>
      <div class="wrappLink"
        v-if="clientsStore.currentClient"
          :class="{
            active:
              router.currentRoute.value.path === '/' ||
              router.currentRoute.value.path === '/create-invoice/' ||
              router.currentRoute.value.path.includes('view-invoice'),
          }"
      >
        <router-link to="/">
          {{ $t("app.sidebar.viewReceipt") }}
        </router-link>
      </div>

      <div
        v-if="clientsStore.currentClient"
        class="wrappLink"
        :class="{
          active:
            router.currentRoute.value.path === '/item-overview' ||
            router.currentRoute.value.path === '/item-overview/create-item',
        }"
      >
        <router-link to="/item-overview">
          {{ $t("app.sidebar.viewItem") }}
        </router-link>
      </div>

      <div
        v-if="clientsStore.currentClient"
        class="wrappLink"
        :class="{ active: router.currentRoute.value.path === '/invoice-issuing' }"
      >
        <router-link to="/invoice-issuing">
          {{ $t("app.sidebar.issueReceipt") }}
        </router-link>
      </div>

      <div
        class="wrappLink"
        :class="{ active: router.currentRoute.value.path === '/technical-docs' }"
      >
        <router-link to="/technical-docs">
          {{ $t("app.sidebar.docs") }}
        </router-link>
      </div>

      <div 
      class="wrappLink"
      :class="{ active: router.currentRoute.value.path === '/profile' }">
        <router-link to="/profile">
          {{ $t("app.sidebar.profile") }}
        </router-link>
      </div>
    </div>
    <!-- end first split -->
    <div class="split">
      <div class="title">
        {{ $t("app.sidebar.titleTwo") }}
      </div>
      <div 
      class="wrappLink"
      :class="{ active: router.currentRoute.value.path === '/companies' }">
        <router-link to="/single-company">
          {{ $t("app.sidebar.companies") }}
        </router-link>
      </div>

      <div
        v-if="clientsStore.currentClient"
        class="wrappLink"
        :class="{
          active:
            router.currentRoute.value.path === 'apiKey'
        }"
      >
        <router-link to="apiKey">
          {{ $t("app.sidebar.apiKey") }}
        </router-link>
      </div>    
    </div>

  </div>
</template>
<script setup>
import { useClientsStore } from "@/store/clientsStore";

const clientsStore = useClientsStore();

const router = useRouter();
let isBurger = inject("isBurger");
</script>
<style lang="scss" scoped>
.sidebar-wrap {
  min-width: 300px;
  // height: 458px;
  height: fit-content;
  padding: 0 21px;
  border: 3px solid $main-gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: space-evenly;
  padding-bottom: 45px;

  .split{
    display: flex;
    flex-direction: column;
    margin-top: 45px;
  }
  .title{
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    padding-left: 35px;
    color: #9C9C9C;
  }

  .wrappLink{
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
    width: 218px;
    padding: 10px 10px 10px 35px;
    transition: all 0.3s ease;
  }
  .active{
    background: #EAF8F5;
    border-radius: 10px;
    
  }
  a{
    display: block;
    text-decoration: none;
    color: $main-dark;
    width: 100%  !important;
    transition: all 0.3s ease;
  }
  a:hover{
    color: $main-green;
  }

  .router-link-exact-active {
    font-weight: 800;
    width: 100%;
    color: $main-green;
  }
}
</style>
