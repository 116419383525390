<template>
<div class="wrappInvoice">
    <div class="title">
        {{$t('app.invoice-overview.viewInvoice.confirmationInvoice')}}
    </div>
    <div class="wrappInfo">
    <ul>
        <li class="entered-articale-single" style="--animation-order: 1;" >
          <div class="entered-name"> {{$t('app.invoice-overview.viewInvoice.PFRtime')}}</div>
          <div class="entered-total">{{ data.billDate }}</div>
        </li>
        <li class="entered-articale-single" style="--animation-order: 2;">
          <div class="entered-name"> {{$t('app.invoice-overview.viewInvoice.PFRnumber')}}</div>
          <div class="entered-total">{{ data.invoiceNumber }}</div>
        </li>
         <li class="entered-articale-single" style="--animation-order: 3;">
          <div class="entered-name"> {{$t('app.invoice-overview.viewInvoice.counter')}}</div>
          <div class="entered-total">{{ data.invoiceCounter }}</div>
        </li>
        <li class="entered-articale-single" style="--animation-order: 4;">
          <div class="entered-name">{{$t('app.invoice-overview.viewInvoice.sumTax')}}</div>
          <div class="entered-total">{{ totalTax }}</div>
        </li>
        <li class="entered-articale-single" style="--animation-order: 5;" >
          <div class="entered-name"> {{$t('app.invoice-overview.viewInvoice.sumPaid')}}</div>
          <div class="entered-total">{{ data.totalAmount }}</div>
        </li>
        </ul>
        <div v-if="data.journal" id="printableArea" class="wrappInvoice1" style="--animation-order: 2; " >
            <pre>
            {{"\r\n" +data.journal?.slice(0,splitBill)}}
            </pre>
            <img style="width:240px; height: 240px" v-if="data.qrCode" :src="'data:image/png;base64,'+data.qrCode" alt="">
            <pre>
            {{"\r\n" +data.journal?.slice(splitBill,data.journal.length )}}
            </pre>
        </div>

    </div>
</div>
</template>

<script setup>
const props = defineProps(['data']);
const totalTax = ref();
const splitBill = ref();

onMounted(()=>{
    if(props.data.journal){
        splitBill.value = props.data.journal.indexOf('======== КРАЈ ФИСКАЛНОГ РАЧУНА =========')
    }
})

// function onBeforeEnter(el) {
//   el.style.opacity = 0
//   el.style.height = 0
// }

// function onEnter(el, done) {
// console.log(el)
//   gsap.to(el, {
//     opacity: 1,
//     height: '38px',
//     delay: el.dataset.index * 0.55,
//     onComplete: done
//   })
// }

// function onLeave(el, done) {
//     console.log(el)
//   gsap.to(el, {
//     opacity: 0,
//     height: 0,
//     delay: el.dataset.index * 0.55,
//     onComplete: done
//   })
// }

</script>

<style lang="scss" scoped>

.wrappInvoice{
    width: 500px;
    .title{
        text-align: center;
        font-weight: 800;
        font-size: 20px;
        line-height: 26.8px;
        margin-bottom: 36px;
    }
    .wrappInfo{
        ul{
            width: 500px;
        }
        .entered-articale-single {
            display: flex;
            justify-content: space-between;
            font-style: normal;
            padding: 7px 30px 7px 30px;
            border-radius: 50px;
            // animation style
            animation-name: animateIn;
            animation-duration: 400ms;
            animation-delay: calc(var(--animation-order) * 150ms);
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
        }
        .entered-articale-single:nth-child(odd) {
            background-color: rgba(246, 246, 246, 1);

        }
        li + li {
            // margin-top: 5px;
        }
        .entered-name {
            color: rgba(59, 65, 97, 1);
            width: 48%;
        }
         .entered-total {
            text-align: right;
            color: rgba(59, 65, 97, 1);
            width: 48%;
            font-weight: 700;

        }
        .wrappInvoice1{
            width: 365px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            // pre{
            //   margin: auto;
            // }
            scale: 0.9;
            margin: auto;
            margin-top: -30px;

            // animation style
            animation-name: animateIn;
            animation-duration: 400ms;
            animation-delay: calc(var(--animation-order) * 150ms);
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
            pre{
                text-align: center;
            }
            img{
                margin:auto;
            }
        }

    }
}
@keyframes animateIn {
  0% {
    opacity: 0;
    transform: scale(0.6) translateY(-8px);
  }
  
  100% {
    opacity: 1;
  }
}
</style>