import { defineStore } from "pinia";
import api from "@/api";
export const useClientsStore = defineStore('Clients', () => {

    let clientLimit = ref(null);
    let allClients = ref(null);
    let currentClient = ref(null);

    async function fetchClients() {
        try {
            let res = await api.getClients();
            if (res.status === 200) {
                clientLimit.value = res.data.meta_info.max_clients;
                allClients.value = res.data.data || [];
                currentClient.value = res.data.data[0] || null;
            }
            console.log(res)
        } catch (error) {
            console.error(error);
        }
    }


    return {
        clientLimit,
        allClients,
        currentClient,
        fetchClients,
    };
});