<template>
  <div class="auth-wrap">
    <div class="main-view">
      <div class="header">
        <div class="logo gap">e<span>Fiskalko</span></div>
        <div class="version">
          {{ $t("app.ver") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <h2>{{ $t("app.auth.registerPage.title") }}</h2>
        </div>
        <div class="inputs-wrap">
          <form class="inputs-wrap" style="width: 100%" autocomplete="off">
            <!-- -->
            <BaseInput
              v-model="name"
              :notification="nameNotif"
              class="mtb-20"
              :label="$t(`app.auth.registerPage.nameLabel`)"
            />
            <BaseInput
              v-model="surname"
              :notification="surnameNotif"
              class="mtb-20"
              :label="$t(`app.auth.registerPage.lastnameLabel`)"
            />
            <BaseInput
              v-model="email"
              :notification="emailNotif"
              class="mtb-20"
              :label="$t(`app.auth.registerPage.emailLabel`)"
            />
            <BaseInput
              v-model="password"
              :notification="passwordNotif"
              class="mtb-20"
              :label="$t(`app.auth.registerPage.passwordLabel`)"
              password
              autocomplete="false"
            />
          </form>
        </div>
        <button class="btn register" @click="register">
          {{ $t("app.auth.registerPage.registerBtn") }}
        </button>
        <div class="msg">{{ msg }}</div>
        <div class="err">{{ err }}</div>
      </div>
      <div class="copyright">
        {{ $t("app.copyrights") }}
      </div>
    </div>
    <div class="info-bar">
      <div class="info-text-wrap">
        <div class="info-title">
          <h2>{{ $t("app.auth.registerPage.sidebarTitle") }}</h2>
        </div>
        <div class="info-text">
          {{$t('app.main.registerTxt')}}
        </div>
        <button class="btn white" @click="goToLogin">
          {{ $t("app.auth.registerPage.sidebarBtn") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { validateEmail } from "@/assets/js/helpers";
import { useI18n } from "vue-i18n";
import api from "../../../api/index";
const { t } = useI18n();

const router = useRouter();

let name = ref("");
let surname = ref("");
let email = ref("");
let password = ref("");

let nameNotif = ref("");
let surnameNotif = ref("");
let emailNotif = ref("");
let passwordNotif = ref("");

let disabled = ref(false);
let msg = ref("");
let err = ref("");

function checkForm() {
  let isValid = true;
  nameNotif.value = "";
  surnameNotif.value = "";
  emailNotif.value = "";
  passwordNotif.value = "";

  //NAME VALIDATION
  if (name.value.length <= 1 || name.value.length > 32) {
    isValid = false;
    nameNotif.value = nameLengthMsg.value;
  }
  if (!name.value) {
    isValid = false;
    nameNotif.value = nameRequiredMsg.value;
  }
  //SURNAME VALIDATION
  if (surname.value.length <= 1 || surname.value.length > 32) {
    isValid = false;
    surnameNotif.value = surNameLengthMsg.value;
  }
  if (!surname.value) {
    isValid = false;
    surnameNotif.value = surNameRequiredMsg.value;
  }
  //EMAIL VALIDATION
  if (!validateEmail(email.value)) {
    isValid = false;
    emailNotif.value = emailInvalidMsg.value;
  }
  if (!email.value) {
    isValid = false;
    emailNotif.value = emailRequiredMsg.value;
  }
  //PASSWORD VALIDATION
  if (!/[A-Z]/.test(password.value)) {
    isValid = false;
    passwordNotif.value = passworUpperCaseMissingMsg.value;
  }
  if (!/[a-z]/.test(password.value)) {
    isValid = false;
    passwordNotif.value = passwordLowerCaseMissingMsg.value;
  }
  if (!/[0-9]/.test(password.value)) {
    isValid = false;
    passwordNotif.value = passwordNumberCaseMissingMsg.value;
  }
  if (password.value.length > 32 || password.value.length < 8) {
    isValid = false;
    passwordNotif.value = passwordLengthMsg.value;
  }
  if (!password.value) {
    isValid = false;
    passwordNotif.value = passwordRequiredMsg.value;
  }

  return isValid;
}

const register = async function () {
  console.log("click");
  msg.value = "";
  err.value = "";
  if (disabled.value) {
    return;
  }
  // eslint-disable-next-line no-constant-condition

  // TODO
  console.log(disabled.value);
  if (checkForm()) {
    disabled.value = true;
    console.log("2q2q");
    const params = {
      email: email.value,
      password: password.value,
      name: name.value,
      last_name: surname.value,
      token: "test",
    };

    try {
      let res = await api.registerApi(params);
      console.log(res.data.res);
      if (res.data.res === "ok") {
        resetFields();
        msg.value = t("app.auth.registerPage.success");
      } else {
        err.value = t("app.auth.registerPage.error");
      }
    } catch (e) {
      console.log(e);
      err.value = t("app.auth.registerPage.error");
    } finally {
      disabled.value = false;
      console.log("end");
    }
  } else {
    disabled.value = false;
  }
};
function resetFields() {
  password.value = "";
  email.value = "";
  name.value = "";
  surname.value = "";
}

const goToLogin = () => {
  router.push("/auth/login");
};

const nameLengthMsg = computed(() =>
  t("app.auth.registerPage.form.nameLength")
);
const nameRequiredMsg = computed(() =>
  t("app.auth.registerPage.form.nameRequired")
);
const surNameLengthMsg = computed(() =>
  t("app.auth.registerPage.form.surNameLength")
);
const surNameRequiredMsg = computed(() =>
  t("app.auth.registerPage.form.surNameRequired")
);
const emailInvalidMsg = computed(() =>
  t("app.auth.registerPage.form.emailInvalid")
);
const emailRequiredMsg = computed(() =>
  t("app.auth.registerPage.form.emailRequired")
);
const passworUpperCaseMissingMsg = computed(() =>
  t("app.auth.registerPage.form.passwordUpperCaseMissing")
);
const passwordLowerCaseMissingMsg = computed(() =>
  t("app.auth.registerPage.form.passwordLowerCaseMissing")
);
const passwordLengthMsg = computed(() =>
  t("app.auth.registerPage.form.passwordLength")
);
const passwordNumberCaseMissingMsg = computed(() =>
  t("app.auth.registerPage.form.passwordNumberCaseMissing")
);
const passwordRequiredMsg = computed(() =>
  t("app.auth.registerPage.form.passwordRequired")
);
</script>

<style lang="scss" scoped>
.gap {
  margin-right: 28px;
}
.white {
  width: 250px;
  height: 50px;
  background-color: #fff;
  color: $main-dark;
}
.content {
  padding: 40px 0;
}
.msg {
  max-width: 90%;
  margin: auto;
  text-align: center;
  color: var(--active-color);
}
.err {
  max-width: 90%;
  margin: auto;
  text-align: center;
  color: var(--erorr-color);
}
@media only screen and (max-width: 800px) {
  .white {
    width: 162px;
    height: 40px;
  }
}
</style>
