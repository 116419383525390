<template>
  <div class="auth-wrap">
    <div class="main-view">
      <div class="header">
        <div class="logo gap">
          e<span>Fiskalko</span>
        </div>
        <div class="version">
          {{ $t("app.ver") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <h2>{{ $t("app.auth.forgorPwPage.title") }}</h2>
        </div>
        <div class="content-subtitle">
          <p>{{ $t("app.auth.forgorPwPage.subtitle") }}</p>
        </div>
        <div class="inputs-wrap">
          <BaseInput class="mtb-20" :label="$t(`app.auth.forgorPwPage.emailLabel`)" />
        </div>
        <button class="btn login">{{ $t("app.auth.forgorPwPage.linkBtn") }}</button>
        <div class="forgor-password">
          <a href="/auth/register">{{ $t("app.auth.forgorPwPage.backBtn") }}</a>
        </div>
      </div>
      <div class="copyright">
        {{ $t("app.copyrights") }}
      </div>
    </div>
    <div class="info-bar">
      <div class="info-text-wrap">
        <div class="info-title">
          <h2>{{ $t("app.auth.forgorPwPage.sidebarTitle") }}</h2>
        </div>
        <div class="info-text">
          {{$t('app.main.registerTxt')}}
        </div>
        <button class="btn white" @click="goToLogin">{{ $t("app.auth.forgorPwPage.sidebarBtn") }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>

const router = useRouter();

const goToLogin = () => {
  router.push("/auth/login");
};

</script>

<style lang="scss" scoped>
.gap {
  margin-right: 28px;
}
.content-subtitle {
  width: 546px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $main-dark;
  margin-top: 0;
  margin-bottom: 10px;
}
.forgor-password {
  width: 546px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  a {
    color: $main-green;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-decoration: none;
  }
}
.white {
  width: 500px;
  height: 50px;
  background-color: #fff;
  color: $main-dark;
}

@media only screen and (max-width: 800px) {
  .content-subtitle {
    width: 90%;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
  .forgor-password {
    width: 100%;
    a {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .white {
    width: 162px;
    height: 40px;
  }
}

</style>
