<template>
  <div class="popup-menu-absolute" @click="emit('closeBurgir')">
    <div class="popup-menu">
      <div id="popup-header">
        <div class="logo">e<span @click="goHome">Fiskalko</span></div>
        <div class="close-btn" @click="emit('closeBurgir')">
          <svg
            width="25"
            height="25"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.195488 1.13939C0.133511 1.07741 0.0843477 1.00383 0.0508058 0.922856C0.0172639 0.841879 6.53038e-10 0.755088 0 0.667439C-6.53038e-10 0.579789 0.0172639 0.492998 0.0508058 0.412021C0.0843477 0.331044 0.133511 0.257465 0.195488 0.195488C0.257465 0.133511 0.331044 0.0843477 0.412021 0.0508058C0.492998 0.0172639 0.579789 -6.53038e-10 0.667439 0C0.755088 6.53038e-10 0.841879 0.0172639 0.922856 0.0508058C1.00383 0.0843477 1.07741 0.133511 1.13939 0.195488L8 7.05743L14.8606 0.195488C14.9226 0.133511 14.9962 0.0843477 15.0771 0.0508058C15.1581 0.0172639 15.2449 0 15.3326 0C15.4202 0 15.507 0.0172639 15.588 0.0508058C15.669 0.0843477 15.7425 0.133511 15.8045 0.195488C15.8665 0.257465 15.9157 0.331044 15.9492 0.412021C15.9827 0.492998 16 0.579789 16 0.667439C16 0.755088 15.9827 0.841879 15.9492 0.922856C15.9157 1.00383 15.8665 1.07741 15.8045 1.13939L8.94257 8L15.8045 14.8606C15.8665 14.9226 15.9157 14.9962 15.9492 15.0771C15.9827 15.1581 16 15.2449 16 15.3326C16 15.4202 15.9827 15.507 15.9492 15.588C15.9157 15.669 15.8665 15.7425 15.8045 15.8045C15.7425 15.8665 15.669 15.9157 15.588 15.9492C15.507 15.9827 15.4202 16 15.3326 16C15.2449 16 15.1581 15.9827 15.0771 15.9492C14.9962 15.9157 14.9226 15.8665 14.8606 15.8045L8 8.94257L1.13939 15.8045C1.07741 15.8665 1.00383 15.9157 0.922856 15.9492C0.841879 15.9827 0.755088 16 0.667439 16C0.579789 16 0.492998 15.9827 0.412021 15.9492C0.331044 15.9157 0.257465 15.8665 0.195488 15.8045C0.133511 15.7425 0.0843477 15.669 0.0508058 15.588C0.0172639 15.507 0 15.4202 0 15.3326C0 15.2449 0.0172639 15.1581 0.0508058 15.0771C0.0843477 14.9962 0.133511 14.9226 0.195488 14.8606L7.05743 8L0.195488 1.13939Z"
              fill="#344368"
            />
          </svg>
        </div>
      </div>
      <!-- <a
        v-if="clientsStore.currentClient"
        @click="emit('closeBurgir')"
        :class="{
          active:
            router.currentRoute.value.path === '/' ||
            router.currentRoute.value.path === '/create-invoice/' ||
            router.currentRoute.value.path.includes('view-invoice'),
        }"
      >
        <router-link to="/">
          {{ $t("app.sidebar.viewReceipt") }}
        </router-link>
      </a>
      <a
        v-if="clientsStore.currentClient"
        @click="emit('closeBurgir')"
        :class="{
          active:
            router.currentRoute.value.path === '/item-overview' ||
            router.currentRoute.value.path === '/item-overview/create-item',
        }"
      >
        <router-link to="/item-overview">
          {{ $t("app.sidebar.viewItem") }}
        </router-link>
      </a>
      <a
        @click="emit('closeBurgir')"
        :class="{ active: router.currentRoute.value.path === '/profile' }"
      >
        <router-link to="/profile">
          {{ $t("app.sidebar.profile") }}
        </router-link>
      </a>
      <a
        @click="emit('closeBurgir')"
        :class="{ active: router.currentRoute.value.path === '/certificates' }"
      >
        <router-link to="/single-company">
          {{ $t("app.sidebar.companies") }}
        </router-link>
      </a>
      <a
        v-if="clientsStore.currentClient"
        @click="emit('closeBurgir')"
        :class="{
          active: router.currentRoute.value.path === '/invoice-issuing',
        }"
      >
        <router-link to="/invoice-issuing">
          {{ $t("app.sidebar.issueReceipt") }}
        </router-link>
      </a>
      <a
        @click="emit('closeBurgir')"
        :class="{
          active: router.currentRoute.value.path === '/technical-docs',
        }"
      >
        <router-link to="/technical-docs">
          {{ $t("app.sidebar.docs") }}
        </router-link>
      </a>

      <a
          v-if="clientsStore.currentClient"
          :class="{
            active:
              router.currentRoute.value.path === 'apiKey'
          }"
          >
            <router-link to="apiKey">
              {{ $t("app.sidebar.apiKey") }}
            </router-link>
      </a> -->

       <div class="split">
      <div class="title">
        {{ $t("app.sidebar.titleOne") }}
      </div>
      <div class="wrappLink"
        v-if="clientsStore.currentClient"
          :class="{
            active:
              router.currentRoute.value.path === '/' ||
              router.currentRoute.value.path === '/create-invoice/' ||
              router.currentRoute.value.path.includes('view-invoice'),
          }"
      >
        <router-link to="/">
          {{ $t("app.sidebar.viewReceipt") }}
        </router-link>
      </div>

      <div
        v-if="clientsStore.currentClient"
        class="wrappLink"
        :class="{
          active:
            router.currentRoute.value.path === '/item-overview' ||
            router.currentRoute.value.path === '/item-overview/create-item',
        }"
      >
        <router-link to="/item-overview">
          {{ $t("app.sidebar.viewItem") }}
        </router-link>
      </div>

      <div
        v-if="clientsStore.currentClient"
        class="wrappLink"
        :class="{ active: router.currentRoute.value.path === '/invoice-issuing' }"
      >
        <router-link to="/invoice-issuing">
          {{ $t("app.sidebar.issueReceipt") }}
        </router-link>
      </div>

      <div
        class="wrappLink"
        :class="{ active: router.currentRoute.value.path === '/technical-docs' }"
      >
        <router-link to="/technical-docs">
          {{ $t("app.sidebar.docs") }}
        </router-link>
      </div>

      <div 
      class="wrappLink"
      :class="{ active: router.currentRoute.value.path === '/profile' }">
        <router-link to="/profile">
          {{ $t("app.sidebar.profile") }}
        </router-link>
      </div>
    </div>
    <!-- end first split -->
    <div class="split">
      <div class="title">
        {{ $t("app.sidebar.titleTwo") }}
      </div>
      <div 
      class="wrappLink"
      :class="{ active: router.currentRoute.value.path === '/companies' }">
        <router-link to="/single-company">
          {{ $t("app.sidebar.companies") }}
        </router-link>
      </div>

      <div
        v-if="clientsStore.currentClient"
        class="wrappLink"
        :class="{
          active:
            router.currentRoute.value.path === 'apiKey'
        }"
      >
        <router-link to="apiKey">
          {{ $t("app.sidebar.apiKey") }}
        </router-link>
      </div>    
    </div>




      <button class="btn logout" @click="logout">
        {{ $t("app.header.headerBtnLogout") }}
      </button>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps } from "vue";
import { logout } from "@/assets/js/helpers";
import { useI18n } from "vue-i18n";
import { useClientsStore } from "@/store/clientsStore"

const clientsStore = useClientsStore();
const { t } = useI18n();
const router = useRouter();

const emit = defineEmits(["closeBurgir"]);
</script>
<style scoped lang="scss">
.popup-menu-absolute {
  z-index: 11;
  position: absolute;
  width: 100vw;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  #popup-header {
    width:100%;
    height: $header-height-mobile;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    padding: 13px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close-btn {
      width: 20px;
      height: 20px;
      right: 5px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .popup-menu {
    z-index: 12;
    max-width: $expanded-sidebar-width;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    // a {
    //   font-style: normal;
    //   text-decoration: none;
    //   font-weight: 800;
    //   color: $main-dark;
    //   font-size: 1.2rem;
    //   margin: 15px auto 15px auto;
    // }
    // .router-link-exact-active {
    //   color: $main-green;
    // }
     .split{
    display: flex;
    flex-direction: column;
    margin-top: 45px;
  }
  .title{
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    padding-left: 35px;
    color: #9C9C9C;
  }

  .wrappLink{
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
    width: 218px;
    padding: 10px 10px 10px 35px;
    transition: all 0.3s ease;

  }
  .active{
    background: #EAF8F5;
    border-radius: 10px;
  }
  a{
    display: block;
    text-decoration: none;
    color: $main-dark;
    width: 100% !important;
    transition: all 0.3s ease;

  }
  a:hover{
    color: $main-green;
  }
  .router-link-exact-active {
    font-weight: 800;
    width: 100%;
    color: $main-green;
  }

    .logout {
      width: 60%;
      padding: 10px;
      position: absolute;
      bottom: 50px;
      left: calc(50% - 30%);
    }
  }
}
@media only screen and (max-width: 600px) {
  .popup-menu {
    max-width: 100% !important;
  }
}
</style>
