<template>
  <div class="forgot-password-wrapper">
    <h1>Resetujte lozinku</h1>
    <form @submit.prevent="submitForm" class="form">
      <label for="oldPassword">Stara lozinka:</label>
      <input v-model="oldPassword" type="password" id="oldPassword" required />

      <label for="newPassword">Nova lozinka:</label>
      <input v-model="newPassword" type="password" id="newPassword" required />

      <button type="submit" class="submit-button">Potvrdi</button>
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";

const oldPassword = ref("");
const newPassword = ref("");

const submitForm = () => {
  console.log("Stara lozinka:", oldPassword.value);
  console.log("Nova lozinka:", newPassword.value);
};
</script>

<style lang="scss" scoped>
.forgot-password-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100vh;
}

h1 {
  color: #344368;
  margin-bottom: 20px;
}

.form {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  background-color: white;
  border: 1px solid #dddfe4;
  border-radius: 4px;
}

label {
  display: block;
  margin-bottom: 10px;
  color: #344368;
}

input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #dddfe4;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

input[type="password"]:hover,
input[type="password"]:focus {
  border-color: #2b8e85;
}

.submit-button {
  background-color: #39aca5;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #2b8e85;
}

.submit-button:active {
  background-color: #1f665d;
}
</style>
