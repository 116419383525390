<template>
  <div class="new-article-section">
    <div 
    v-if="store.getInvoiceAction !== 'complete-ADVANCE'"
    id="add-article">
      <span v-if="isEditing">{{ $t("app.create-invoice.editArticle") }}</span>
      <span v-else > {{ $t("app.create-invoice.addArticle") }}</span>
    </div>

    <div
     v-if="store.getInvoiceAction !== 'complete-ADVANCE'"
     >
     <BaseInput
        v-if='isEditing'
        class="mr-spacing"
        :label="$t('app.create-invoice.gtin')"
        v-model="currentArticle"
        :disabled='
         store.getInvoiceAction === "addOn-ADVANCE"
         || store.getInvoiceAction === "complete-ADVANCE"
        '
      ></BaseInput>
    <BaseSelect
      v-else
      class="wide-input"
      :showTopLabel="true"
      :placeholder="$t('app.create-invoice.articleName')"
      :applyWrap="true"
      :topLabelText="$t('app.create-invoice.articleName')"
      :options="getArticlesOptions"
      :searchable="true"
      :taggable="true"
      :loading="getIsArticlesLoading"
      :notification="currentArticleNotif"
      :value="currentArticle"
      @selected="setCurrentArticle"
      @onInput="(val) => getArticles(val)"
      :disabled='store.getInvoiceAction === "addOn-ADVANCE"
      || store.getInvoiceAction === "complete-ADVANCE"'
    ></BaseSelect>
    <div class="input-grp">
      <BaseInput
        class="mr-spacing"
        :label="$t('app.create-invoice.gtin')"
        v-model="currentGtin"
        :closeOnSelect="true"
        @input="(val) => (currentGtin = val)"
        :disabled='store.getInvoiceAction === "addOn-ADVANCE"
        || store.getInvoiceAction === "complete-ADVANCE"'
      ></BaseInput>
      <BaseInput
        :label="$t('app.create-invoice.price')"
        :onlyNumbersAndDot="true"
        v-model="currentPrice"
        :notification="currentPriceNotif"
        @input="(val) => (currentPrice = val)"
        type="number"
        :disabled='store.getInvoiceAction === "addOn-ADVANCE"
         || store.getInvoiceAction === "complete-ADVANCE"'
      ></BaseInput>
    </div>
    <div class="input-grp">
      <BaseSelect
        class="mr-spacing"
        :topLabelText="$t('app.create-invoice.tax')"
        :options="getCurrentTaxes"
        :showTopLabel="true"
        :applyWrap="true"
        :placeholder="$t('app.create-invoice.tax')"
        :notification="currentTaxSelectedNotif"
        :value="currentTaxSelected"
        @selected="(val) => (currentTaxSelected = val)"
        :disabled='store.getInvoiceAction === "addOn-ADVANCE"
         || store.getInvoiceAction === "complete-ADVANCE"'
      ></BaseSelect>
      <BaseInput
        :label="$t('app.create-invoice.articleQuantity')"
        :onlyNumbersAndDot="true"
        v-model="currentQuantity"
        :notification="currentQuantityNotif"
        @input="(val) => (currentQuantity = val)"
        type="number"
        :disabled='store.getInvoiceAction === "addOn-ADVANCE"
        || store.getInvoiceAction === "complete-ADVANCE"'
      ></BaseInput>
    </div>
    <div class="input-grp">
      <BaseInput
        class="mr-spacing"
        :label="$t('app.create-invoice.articlePrice')"
        :onlyNumbers="true"
        v-model="getCurrentArticleTotalPrice"
        :disabled="true"
      ></BaseInput>
      <div class="buttons-wrapper">
        <div class="edit-buttons" v-if="isEditing">
          <button class="btn cancel" @click="cancelEdit">
            {{ $t("app.create-invoice.cancel") }}
          </button>
          <button class="btn" @click="editArticle">
            {{ $t("app.create-invoice.change") }}
          </button>
        </div>
        <div v-else-if="store.getInvoiceAction !== 'addOn-ADVANCE'" class="add-wrap">
          <button class="btn" @click="addArticle" >
            {{ $t("app.create-invoice.addArticle") }}
          </button>
        </div>
      </div>
    </div>
    
    <div 
      v-if="invoiceType ==='ADVANCE'"
      class="input-grp"
    >
      <BaseInput
        class="mr-spacing"
        :label="$t('app.create-invoice.amountPaid')"
        :onlyNumbers="true"
        v-model="amountPaid"
        :notification="currentAmountNotif"
        type="number"
      ></BaseInput>
    </div>

    <BaseInput
      class="wide-input"
      :label="$t('app.create-invoice.billPrice')"
      :disabled="true"
      v-model="getTotalBillAmount"
    ></BaseInput>
  </div>

    <div class="entered-articles">
      <div v-if="billArticles?.length > 0" class="entered-header">
        <div class="entered-header-name" :style='{width: invoiceType !== "ADVANCE" ? "40%" : ""}'>
          {{ $t("app.create-invoice.articleName") }}
        </div>
        <div
          v-if='invoiceType === "ADVANCE"'
          class="entered-header-name"
        >
          {{ $t("app.invoice-overview.viewInvoice.paid") }}
        </div>
        <div 
          v-if='invoiceType === "ADVANCE"'
          class="entered-header-name"
         >
          {{ $t("app.invoice-overview.viewInvoice.totalPaid") }}
        </div>
        <div class="entered-header-total">
          {{ $t("app.create-invoice.articlePrice") }}
        </div>
      </div>
      <div class="entered-articles-content custom-scollbar">
        <div
          class="entered-articale-single"
          v-for="(article, index) in billArticles"
          :key="index"
          :class="[
            { 'green-article': !(index % 2) },
            { 'selected-article': index == editingIndex },         
          ]"
        >
          <div class="entered-name" :style='{width: invoiceType !== "ADVANCE" ? "40%" : ""}'>{{ article.name }}</div>
          <div 
            v-if='invoiceType === "ADVANCE"' 
            class="entered-name"
          >{{ article.amountPaid }}</div>
          <div 
            v-if='invoiceType === "ADVANCE"' 
            class="entered-name"
          >{{article.newTotalPaid ? article.newTotalPaid :  article.totalPaid }}</div>
          
          <div class="entered-total">
            {{ article.art_price_total }}

            <div class="entered-actions">
              <div class="entered-edit" @click="setArticleForEdit(index)">
                <svg
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.98121 1.59735L7.17448 3.79071L2.41189 8.5535L0.456417 8.76938C0.194637 8.79833 -0.0265402 8.57697 0.00258457 8.31518L0.220164 6.35825L4.98121 1.59735ZM8.53101 1.27079L7.50119 0.240932C7.17996 -0.0803107 6.65897 -0.0803107 6.33774 0.240932L5.36891 1.2098L7.56218 3.40316L8.53101 2.43429C8.85224 2.11288 8.85224 1.59204 8.53101 1.27079Z"
                    fill="#888889"
                  />
                </svg>
              </div> 
              <div class="entered-delete" @click="removeArticleFromBill(index)">
                <svg
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.06386 1.5786H6.66145V0.7016C6.66145 0.314624 6.347 0 5.96024 0H2.45422C2.06746 0 1.75301 0.314624 1.75301 0.7016V1.5786H0.350602C0.156675 1.5786 0 1.73536 0 1.9294V2.2802C0 2.32843 0.0394428 2.3679 0.0876506 2.3679H0.749413L1.02003 8.10129C1.03756 8.47511 1.34653 8.77 1.72014 8.77H6.69432C7.06902 8.77 7.3769 8.4762 7.39443 8.10129L7.66505 2.3679H8.32681C8.37502 2.3679 8.41446 2.32843 8.41446 2.2802V1.9294C8.41446 1.73536 8.25778 1.5786 8.06386 1.5786ZM5.87259 1.5786H2.54187V0.7893H5.87259V1.5786Z"
                    fill="#888889"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="store.getInvoiceAction === 'complete-ADVANCE'"
      class="wrappImg"
    >
     <div
          class="entered-articale-single"
          style="width:100%; border-top: 1px solid #dddfe4;border-radius:0"
        >
          <div 
            class="entered-name"
            style="width:50%"
          >{{ $t("app.invoice-overview.viewInvoice.sumTotalPaid") }}</div>
          <div 
            class="entered-name"
            style="width: 40%"
          >{{ getTotalPaidSum }}</div>
        </div>
    <img src="../../../assets/images/view-invoice.png" alt="">

    </div>
  </div>
</template>
<script setup>
import api from "@/api/index";
import { useClientsStore } from "@/store/clientsStore";
import { useI18n } from "vue-i18n";
import {
  validateTwoDecimal,
  validateThreeDecimal,
} from "../../../assets/js/helpers";
import { defaultStore } from "@/store/index.js"
const { t } = useI18n();
const clientStore = useClientsStore();
const store = defaultStore();

const props = defineProps(['billArticles', 'taxLabels', 'invoiceType'])
const emit = defineEmits(['add', 'remove', 'edit', 'remove-all-article'])
let isEditing = ref(false);
let editingIndex = ref();

let articles = ref([]);
let isArticlesLoading = ref(false);
// let billArticles = ref([
//   {
//     name: "Svargla",
//     gtin: "561329319123",
//     quantity: 1,
//     labels: ["Ж"],
//     unitPrice: 351,
//     art_price_total: "351.00",
//   },
// ]);

let clientTaxLabels = ref([]);

let currentArticle = ref();
let currentGtin = ref();
let currentPrice = ref();

let currentQuantity = ref();
let currentTaxSelected = ref([]);
let currentUnitPrice = ref();

let currentArticleNotif = ref();
let currentPriceNotif = ref();
let currentTaxSelectedNotif = ref();
let currentQuantityNotif = ref();
let currentAmountNotif = ref();

// only advance
const  amountPaid = ref();
const currentArticleTotalPaid = ref();
// end only advance

const storeInvoiceAtion = ref();


onMounted(async () => {
  // await getClientTaxLabels();
  await getArticles();
  storeInvoiceAtion.value = store.getInvoiceAction

});

const getArticlesOptions = computed(() => {
  return articles.value.map((el) => ({ label: el.art_name, id: el.art_id }));
});

const getIsArticlesLoading = computed(() => isArticlesLoading.value);

// problem: when it is declared as scoped, the tax cannot be reset when changing the type of account. 
// This happens because the list of items only shows the first 10
const rawArticle = ref(null);

const setCurrentArticle = (selectedArticle, setNewArticle = true) => {
  if (isEditing.value) return;

  resetCurrentArticleNotifs();

// set for dropdown label
if(setNewArticle){
  currentArticle.value = selectedArticle;
}
 
// set data to edit
  if (typeof selectedArticle === "object" && selectedArticle?.id) {
    // console.log("Stored article:", selectedArticle);

    /* 
      let rawArticle = articles.value.find(
      (el) => el.art_id === selectedArticle.id
    */
            // if (rawArticle.art_base_labels && rawArticle.art_base_labels?.length > 0) {
            // let taxToSet = props.taxLabels.find(
            //   (el) => el.label == rawArticle.art_base_labels[0]
            // );
            // clientTaxLabels.value.find(
              // (el) => el.label == rawArticle.art_base_labels[0]
            // );

       if(setNewArticle){
          rawArticle.value = articles.value.find(
            (el) => el.art_id === selectedArticle.id
          );

          // console.log(rawArticle.value)
          currentGtin.value = rawArticle.value.art_gtin;
          currentPrice.value = rawArticle.value.art_base_price;
          currentQuantity.value = 1;
        }   

      let taxToSet = setTaxLabel(rawArticle.value)
      currentTaxSelected.value = taxToSet ? taxToSet.label + ": " + taxToSet.rate + "%" : null;
    
  } else {
// set an article that is not listed
    console.log("Custom article:", currentArticle.value);
    resetCurrentArticle();
    currentArticle.value = selectedArticle;
    currentQuantity.value = 1;
  }
};

function setTaxLabel(article){
  if(!article.art_base_labels){
    return null
  }
  for(let i = 0; i < article.art_base_labels.length; i++) {
    for(let j= 0; j < props.taxLabels.length; j++) {
      if(article.art_base_labels[i] === props.taxLabels[j].label){
        return props.taxLabels[j]
      }
    }
  }
  return null
}

const setArticleForEdit = (index) => {
  resetCurrentArticle();
  resetCurrentArticleNotifs();

  let article = props.billArticles[index];
  isEditing.value = true;
  editingIndex.value = index;
  const tax = formatLabelToDispay(
    // clientTaxLabels.value.find((el) => el.label == article.labels[0])
    props.taxLabels.find((el) => el.label === article.labels[0])
  );
  console.log(article)
  currentArticle.value = article.name;
  currentGtin.value = article.gtin;
  currentPrice.value = article.art_price_total;
  currentTaxSelected.value = [tax];
  currentQuantity.value = article.quantity;
  currentArticleTotalPaid.value = article.totalPaid;
  currentUnitPrice.value= article.unitPrice
  // amountPaid.value = article.
};

const cancelEdit = () => {
  isEditing.value = false;
  editingIndex.value = null;
  resetCurrentArticle();
};
const editArticle = () => {
  resetCurrentArticleNotifs();
  // let test = billArticles[editingIndex.value]
 
  console.log(typeof currentTaxSelected.value );
  console.log(currentTaxSelected.value[0])
  let tax = 
    typeof currentTaxSelected.value !== 'object' ? 
    currentTaxSelected.value.split(':')[0] : 
    currentTaxSelected.value[0].split(':')[0]

  let obj={
    name: currentArticle.value,
    gtin: currentGtin.value,
    art_price_total: currentPrice.value,
    // art_base_labels
    labels: tax,
    quantity : currentQuantity.value,
    unitPrice: currentUnitPrice.value
  }
  if(props.invoiceType === "ADVANCE") {
    if(currentArticleTotalPaid.value
    && (Number(currentArticleTotalPaid.value) + Number(amountPaid.value)) > getCurrentArticleTotalPrice.value ){
       
       currentAmountNotif.value = t("app.create-invoice.amountToBig")
       return
    } else if( currentArticleTotalPaid )  {
      obj.totalPaid = Number(currentArticleTotalPaid.value)
      obj.newTotalPaid =  (Number(currentArticleTotalPaid.value) + Number(amountPaid.value))
    }
    obj.amountPaid = Number(amountPaid.value)
  }
  console.log(obj)
  emit('edit',editingIndex.value, obj )

  resetCurrentArticle()
  resetCurrentArticleNotifs()

};

const resetCurrentArticle = () => {
  isEditing.value = false
  editingIndex.value = null
  currentArticle.value = null;
  currentGtin.value = null;
  currentPrice.value = null;
  currentTaxSelected.value = [];
  currentQuantity.value = null;
  amountPaid.value = null;
};
const resetCurrentArticleNotifs = () => {
  currentArticleNotif.value = null;
  currentPriceNotif.value = null;
  currentTaxSelectedNotif.value = null;
  currentQuantityNotif.value = null;
  currentAmountNotif.value = null;
};
const addArticle = (val) => {
  if (validateFormAndSetErrors()) {
    let articleToStore = currentArticleData();
    console.log(articleToStore);
    // billArticles.value.push(articleToStore);
    emit('add', articleToStore);

    resetCurrentArticle();
    resetCurrentArticleNotifs();
  }
};
const validateFormAndSetErrors = () => {
  resetCurrentArticleNotifs();
  let isCorrect = true;
  if (!currentArticle.value) {
    currentArticleNotif.value = t("app.create-invoice.requiredField");
    isCorrect = false;
  }
  if (!currentPrice.value) {
    currentPriceNotif.value = t("app.create-invoice.requiredField");
    isCorrect = false;
  } else if (!validateTwoDecimal(currentPrice.value)) {
    currentPriceNotif.value = t("app.create-invoice.invalidPriceFormat");
    isCorrect = false;
  }
  if (!currentTaxSelected.value || currentTaxSelected.value.length === 0) {
    currentTaxSelectedNotif.value = t("app.create-invoice.requiredField");
    console.log("Missing article data!");
    isCorrect = false;
  }
  if (!currentQuantity.value) {
    currentQuantityNotif.value = t("app.create-invoice.requiredField");
    isCorrect = false;
  } else if (!validateThreeDecimal(currentQuantity.value)) {
    currentQuantityNotif.value = t("app.create-invoice.invalidQuantFormat");
    isCorrect = false;
  }
   if(props.invoiceType === "ADVANCE" &&  !amountPaid.value) {
    currentAmountNotif.value = t("app.create-invoice.requiredField")
    isCorrect = false;
  }
  return isCorrect;
};
const currentArticleData = () => {
  let article = {
    name: currentArticle?.value?.label || currentArticle.value,
    gtin: currentGtin.value,
    quantity: currentQuantity.value,
    labels: [...currentTaxSelected.value.slice(0, 1)],
    unitPrice: currentPrice.value,
    art_price_total: getCurrentArticleTotalPrice.value,
    amountPaid: amountPaid.value
  };
   console.log(article)
  return article;
};
// const getCurrentTaxes = computed(() => {
//   let res = clientTaxLabels.value.map((el) => formatLabelToDispay(el));
//   return res;
// });
const getTotalPaidSum = computed(()=>{
  let res = 0
  for(let i = 0; i < props.billArticles.length; i++){
    if(props.billArticles[i].totalPaid)
    res+= props.billArticles[i].totalPaid
  }
  return res
});
const getCurrentTaxes = computed(() => {
  let res = props.taxLabels.map((el) => formatLabelToDispay(el));
  return res;
});
const formatLabelToDispay = (el) => el.label + ": " + el.rate + "%";
const getCurrentArticleTotalPrice = computed(() => {
  let res = (currentPrice.value * currentQuantity.value).toFixed(2);
  isNaN(res) ? (res = "") : (res = res.toString());
  return res;
});
const getTotalBillAmount = computed(() => {
  if(props.billArticles)
  return props.billArticles
    .reduce((agg, curr) => agg + Number.parseFloat(curr.art_price_total), 0)
    .toFixed(2);
});
const removeArticleFromBill = (index) => {
  // billArticles.value.splice(index, 1);
  emit('remove', index);
};
const getArticles = async (search) => {
  let params = {
    cli_id: clientStore.currentClient.cli_id,
    search: search || "",
  };
  try {
    isArticlesLoading.value = true;
    let res = await api.searchArticles(params);
    if (res.status === 200) {
      articles.value = [...res.data.data];
    }
  } catch (error) {
    console.error(error);
  } finally {
    isArticlesLoading.value = false;
  }
};
// const getClientTaxLabels = async () => {
//   try {
//     let params = {
//       cli_id: clientStore.currentClient.cli_id,
//     };
//     let res = await api.getTaxLabels(params);
//     if (res.status === 200) {
//       clientTaxLabels.value = res.data.data;
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

watch(
  ()=>[props.invoiceType,props.taxLabels],
  ([newValInvoice, newValueTax ], [oldValInvoice, oldValueTax ])=> {
   
    if(newValInvoice === 'ADVANCE' /* because advance sales have different taxes */
      && newValInvoice !== oldValInvoice
      && oldValInvoice)  /* so that the articles are not deleted if some function is performed from the beginning - and there are already existing articles */
    {
      console.log('invoice')
      console.log(oldValInvoice)
      emit('remove-all-article')
    }
    if(newValueTax.length <= 4 && newValueTax !== oldValueTax){
      console.log('tax')
      if(currentArticle.value) {
        setCurrentArticle(currentArticle.value, false)

      }
    }
  }
)

defineExpose({
  validateFormAndSetErrors,
  resetCurrentArticle
});

</script>
<style scoped lang="scss">
.new-article-section {
  min-width: 400px;
  width: 50%;
  color: $main-dark;
  min-height: 100%;
  min-height: calc(100vh - 250px);
  // display: flex;
  // flex-direction: column;
  #add-article {
    position: absolute;
    top: -20px;
    font-weight: 700;
  }
  .buttons-wrapper {
    min-width: 50%;
    display: flex;

    .add-wrap {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      .btn {
        margin: auto 0 20px auto;
      }
    }
    .edit-buttons {
      display: flex;
      margin-left: auto;

      .btn {
        margin: auto 0 20px 0;
      }
      .cancel {
        margin-right: $spacing-margine;
        background-color: $main-dark;
      }
    }
  }
  .input-grp {
    display: flex;
    .input-wrap,
    .multiselect-wrap {
      // margin-right: $spacing-margine;
      max-width: unset;
    }
  }
  .multiselect-wrap {
    max-width: 100%;
  }
  .entered-articles {
    display: flex;
    flex-direction: column;
  }
  .entered-header,
  .entered-articale-single,
  .entered-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 700;
  }
  .entered-articles-content {
    padding-right: $spacing-margine;
    max-height: 250px;
    overflow-y: auto;
  }
  .entered-header {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #98a0b6;
    margin: 20px 0 0 30px;
  }
  .entered-header-total,
  .entered-total {
    // max-width: 200px;
    min-width: 120px;
    padding-right: 15px;
    width: 20%;
    text-align: left;
  }
  .entered-header-total {
    margin-right: 20px;
  }
  .entered-header-name{
    width: 22%
  }
  .entered-name {
    color: rgba(59, 65, 97, 1);
    width: 22%;
  }
  .entered-actions {
    width: 24px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    visibility: hidden;
    .entered-edit,
    .entered-delete {
      padding: 0 5px;
    }
  }
  .entered-articale-single {
    padding: 7px 0 7px 30px;
    border-radius: 50px;
    &:hover {
      .entered-actions {
        visibility: visible;
      }
    }
  }
  .green-article {
    background-color: rgba(246, 246, 246, 1);
  }
  .selected-article {
    background-color: rgba(69, 222, 192, 0.39);
    .entered-actions {
      visibility: visible;
    }
  }

  .wrappImg{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 50%;
    width: 100%;
    img{
     max-height: 265px;
    }
  }
}
</style>
