<template>
  <div class="wrapapiModal">
    <div class="wrappInput">
        <div class="label" >
            {{props.options.text ? $t(props.options.text) : $t('app.main.whetherToDelete') }} 
            <span style="color:red;opacity:0.7">{{props.options.name}}</span> ?
        </div>
        <div @click="deleteKey" class="btn keyButton">
            {{props.options.btnName ? $t(props.options.btnName) : $t('app.api.delete')}}
        </div>

    </div> 
  </div>
</template>

<script setup>
import { useClientsStore } from "@/store/clientsStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api"

const clientsStore = useClientsStore();
const modalStore = ModalStore();

const props = defineProps(["options"]);

async function deleteKey(){
    props.options.confirm();
    modalStore.setModalData(null);
}
</script>

<style lang="scss" scoped>
.wrapapiModal{
     width: 400px;
}
.wrappInput{
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    .label{
        min-width: 150px;
        width: 100%;
        word-wrap: wrap;
        // white-space: nowrap;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 20px;
        /* identical to box height */


        color: #98A0B6;

    }
}


</style>