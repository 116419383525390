<template lang="pug">
teleport(to="head title")
  | {{ appMetaTitle }}
router-view(v-if="dataFetched")
<transition name="fade">
  <wrapp-modal/>
</transition>
</template>

<script setup>
import { defaultStore } from "@/store/index.js";
import { provide } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useUserStore } from "./store/userStore";
import { useClientsStore } from "./store/clientsStore";
import { getStorageEl, removeStorageEl } from "./assets/js/helpers";
import router from "./router";
import api from "@/api"

const appMetaTitle = "eFiskalko";

let dataFetched = ref(false);
const store = defaultStore();
const userStore = useUserStore();
const clientsStore = useClientsStore();

const MOBILE_BREAK = 800;
const BURGER_BREAK = 1400;

let isMobile = ref(false);
let isBurger = ref(false);

const handleResize = () => {
  store.setIsMobile(window.innerWidth <= MOBILE_BREAK);
  store.setIsBurger(window.innerWidth <= BURGER_BREAK);

  isMobile.value = window.innerWidth <= MOBILE_BREAK;
  isBurger.value = window.innerWidth <= BURGER_BREAK;
};

provide("isMobile", isMobile);
provide("isBurger", isBurger);

const hideRecaptchaBadge = async () => {
  const recaptcha = useReCaptcha();
  await recaptcha?.recaptchaLoaded();
  recaptcha?.instance.value?.hideBadge();
};

async function handleSession() {
  let sid = getStorageEl("sid");
  if (sid) {
    try {
      await userStore.checkSession();
    } catch (error) {
      removeStorageEl("sid");
      userStore.$reset();
      goToLogin();
    }
  } else {
    console.log('hnhnhn')
    removeStorageEl("sid");
    userStore.$reset();
  }
}
const goToLogin = () => {
  router.push("/auth/login");
};

async function init () {
  checkSession();
  if (userStore.isLoggedIn) {
      await clientsStore.fetchClients();
  }
  window.addEventListener("resize", handleResize);
  handleResize();
  dataFetched.value = true;

}
setInterval(() => {
  // if(localStorage.getItem("sid"))
    checkSession();
  // else{ 
  //     console.log('x')
  //     userStore.sid = null    
  //     userStore.id = null;
  //     userStore.name = null;
  //     userStore.isLoggedIn = false;
  //     router.push("/auth/login");
  // }
}, 30000);
async function checkSession(){
  try{
    const res = await api.checkSession();
     if (res.status === 200) {
        userStore.sid = localStorage.getItem("sid")    
        userStore.id = res.data.id;
        userStore.name = res.data.name;
        userStore.isLoggedIn = true;
     } else{
     router.push("/auth/login");
     }
  }catch(e){
      userStore.sid = null    
      userStore.id = null;
      userStore.name = null;
      userStore.isLoggedIn = false;
      localStorage.removeItem("sid")
    router.push("/auth/login");
    // console.log(e)
  }

}

onBeforeMount(() => {
  // setup();
  init();
  hideRecaptchaBadge();
});

async function setup() {
  hideRecaptchaBadge();
  window.addEventListener("resize", handleResize);
  handleResize();
  try {
    await handleSession();
    if (userStore.isLoggedIn) {
      await clientsStore.fetchClients();
    }
  } catch (error) {
    console.error(error);
  } finally {
    dataFetched.value = true;
  }
}

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<style lang="scss">
@import "./assets/css/base.css";

#app {
  min-height: 100vh;
  height: fit-content;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
</style>
