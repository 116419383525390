<template>
  <div class="create-invoice-wrapper">
    <div class="create-invoice-header">
      <h2 v-if='store.getInvoiceAction === "addOn-ADVANCE"'>
        {{$t("app.create-invoice.addOnAdvance")}}
      </h2>
      <h2 v-else-if='store.getInvoiceAction === "complete-ADVANCE"'>
        {{$t("app.create-invoice.addOnComplete")}}
      </h2>
      <h2 v-else >
        {{ $t("app.create-invoice.title") }}
      </h2>
      <div class="header-btns">
        <a class="delete" @click="deleteInvoice">
          {{ $t("app.create-invoice.delete") }}
        </a>
        <a class="btn add" @click="createInvoice">
          {{ $t("app.create-invoice.issue") }}
        </a>
        
        <label
          v-if="errMessage"
          :class="{ shakeWarn: isShaking }"
          class="notif-label"
        >
          {{ errMessage }}
        </label>
      </div>
      
    </div>
     <label class="sucess">
      {{message !== '' ? $t(message) : message}}
     </label>
    <div class="content-wrap">
      <PaymentAndDataTool 
        ref="paymentAndDataToolRef"
        :invoice="invoice"
        @setData="getPayment"
        @selectInvoiceType="getClientTaxLabels"/>
      <ArticlesTool 
        ref="ArticlesToolRef"
        :billArticles="billArticles"
        :taxLabels="clientTaxLabels"
        :invoiceType="invoiceType"
        @add="addArticle"
        @remove="removeArticle"
        @remove-all-article="removeAllArticle"
        @edit="editArticle"
       />
    </div>
  </div>
</template>

<script setup>
import { useClientsStore } from "@/store/clientsStore";
import api from "@/api"
import { useI18n } from "vue-i18n";
import { defaultStore } from "@/store/index.js";
import { useTaxStore } from "@/store/taxStore.js";
import { ModalStore } from "@/store/modalStore";

const store = defaultStore();
const modalStore = ModalStore();

const invoice = ref(null);
const { t } = useI18n();
const paymentAndDataToolRef = ref(null);
const ArticlesToolRef = ref(null);
const clientsStore = useClientsStore();
const taxStore = useTaxStore();
const message = ref('');
const errMessage = ref('');
const clientTaxLabels = ref([]);
const invoiceType = ref([]);

// data for send 
const paymentObj = ref(null);

const billArticles = ref([
  // {
  //   name: "Svargla",
  //   gtin: "561329319123",
  //   quantity: 1,
  //   labels: ["Ж"],
  //   unitPrice: 351,
  //   art_price_total: "351.00",
  // },
]);

onMounted(() => {
  let id = store.getInvoiceForAction
  if(id) {
    getInvoice(id);
  }
  getClientTaxLabels();
})
onUnmounted(()=> {
  // resetValue();
  store.setInvoiceAction(null)
  store.setInvoiceForAction(null)
})

function createInvoice() {
  paymentAndDataToolRef.value.emitData();
  if(billArticles.value?.length <= 0){
   let haveData =  ArticlesToolRef.value.validateFormAndSetErrors();
   console.log(haveData)
   if(haveData) {
    errMessage.value = t("app.invoice-issue.errSetArticle")
   }
    return
  }
  console.log(4)

  if(paymentObj.value !== null){
    sendData()
  }

}


function sendData (){

  if(!clientsStore.currentClient)
    return
  if(!paymentObj.value)
    return
    let params = {
      cli_id: clientsStore.currentClient.cli_id,
      data:{}
    };
    for(let item in paymentObj.value) {
      params.data[item] = paymentObj.value[item]
    }

 // only articles witch have paidAmount 
 // addOn-ADVANCE
  if (
      paymentObj.value.transactionType.label === 'SALE'
      && paymentObj.value.invoiceType.label === "ADVANCE"
      && store.getInvoiceAction === "addOn-ADVANCE") {
        params.data.items = []
        for(let i = 0; i < billArticles.value.length; i++) {
          if(billArticles.value[i].amountPaid > 0) {
            let obj = Object.assign({}, billArticles.value[i])
            obj.labels = [obj.labels]
              params.data.items.push(obj)
          }
        }
    } else {
      params.data.items = billArticles.value
    }
    console.log(params);


    //// call api 

      if (paymentObj.value.transactionType.label === 'SALE'
      && paymentObj.value.invoiceType.label === 'NORMAL'){
        normalSale(params)

      } else if (paymentObj.value.transactionType.label === 'REFUND'
      && paymentObj.value.invoiceType.label === 'NORMAL'){
        normalRefund(params)

      } else if (paymentObj.value.transactionType.label === 'SALE'
      && paymentObj.value.invoiceType.label === 'TRAINING') {
        trainingSale(params)

      } else if (paymentObj.value.transactionType.label === 'REFUND'
      && paymentObj.value.invoiceType.label === 'TRAINING') {
        trainingRefund(params)

      } else if (
         paymentObj.value.transactionType.label === 'SALE'
      && paymentObj.value.invoiceType.label === 'ADVANCE'
      && store.getInvoiceAction === 'addOn-ADVANCE') {
        console.log(invoice.value.billId)
        advanceSaleAdd(invoice.value.billId, params)

      }  else if (
         paymentObj.value.transactionType.label === 'SALE'
      && paymentObj.value.invoiceType.label === 'ADVANCE'
      && store.getInvoiceAction === 'complete-ADVANCE') {
        console.log(invoice.value.billId)
        console.log('complete-ADVANCE')
        advanceSaleComplete(invoice.value.billId, params)

      } else if (
         paymentObj.value.transactionType.label === 'SALE'
      && paymentObj.value.invoiceType.label === 'ADVANCE')
      {
        advanceSale(params)

      } else if (
         paymentObj.value.transactionType.label === 'REFUND'
      && paymentObj.value.invoiceType.label === 'ADVANCE') {
        advanceRefund(params)
      } 
      // kkkk
      else if (
         paymentObj.value.transactionType.label === 'SALE'
      && paymentObj.value.invoiceType.label === 'PROFORMA') {
        proformSale(params)

      }  else if (
         paymentObj.value.transactionType.label === 'REFUND'
      && paymentObj.value.invoiceType.label === 'PROFORMA') {
        proformRefund(params)
        
      } else if ( 
         paymentObj.value.transactionType.label === 'SALE'
      && paymentObj.value.invoiceType.label === 'COPY') {
        copyInvoice(params)

      }  else if ( 
         paymentObj.value.transactionType.label === 'REFUND'
      && paymentObj.value.invoiceType.label === 'COPY') {
        copyRefund(params)

      }

}


// API CALL FUNCTION 

async function normalSale(params) {
  
  try {
  let res = await api.createInvoice(params);
        console.log(res)
        showModalInvoice(res.data.data)
        result()
  } catch (error) {
    // console.log(error)
    // console.log(error.response.data.message)
    // errMessage.value = t("app.invoice-issue.error")
    setBackMsg(error.response.data.message)

  }
}

async function normalRefund(params) {
  
  try {
  let res = await api.createRefund(params);
        console.log(res)
         showModalInvoice(res.data.data)
        result()
  } catch (error) {
    setBackMsg(error.response.data.message)

  }
}

async function trainingSale(params) {
 
  try {
  let res = await api.createTrainingInvoice(params);
        console.log(res)
         showModalInvoice(res.data.data)
        result()
  } catch (error) {
    setBackMsg(error.response.data.message)
  }
}

async function trainingRefund(params) {
 
  try {
  let res = await api.createTrainingRefund(params);
        console.log(res)
         showModalInvoice(res.data.data)
        result()
  } catch (error) {
    setBackMsg(error.response.data.message)
  }
}

async function advanceSale(params) {
  try {
  let res = await api.createAdvanceInvoice(params);
        console.log(res)
         showModalInvoice(res.data.data)
        result()
  } catch (error) {
    setBackMsg(error.response.data.message)
  }
}

async function advanceSaleAdd(id, params) {
  try {
  let res = await api.createAdvanceSaleAdd(id, params);
        console.log(res)
        showModalInvoice(res.data.data)
        result()
  } catch (error) {
    setBackMsg(error.response.data.message)
  }
}

async function advanceSaleComplete(id, params){
   try {
   let res = await api.createAdvanceSaleComplete(id, params);
          console.log(res)
          showModalInvoice(res.data.data, res.data.refund)
          result()
    } catch (error) {
      setBackMsg(error.response.data.message)
    }
}

async function advanceRefund(params) {
  try {
  let res = await api.createAdvanceRefund(params);
        console.log(res)
         showModalInvoice(res.data.data, res.data.refund)
        result()
  } catch (error) {
    setBackMsg(error.response.data.message)
  }
}
/// new
async function proformSale(params) {
  try {
    console.log("zovi api kada dobijes proformSale")
    let res = await api.createProformaSale(params);
          console.log(res)
          showModalInvoice(res.data.data)
          result()
  } catch (error) {
    setBackMsg(error.response.data.message)
  }
}

async function proformRefund(params) {
  try {
    console.log("zovi api kada dobijes proformRefund")
  let res = await api.createProformaRefund(params);
        console.log(res)
         showModalInvoice(res.data.data)
        result()
  } catch (error) {
    setBackMsg(error.response.data.message)
  }
}

async function copyInvoice(params) {
  try {
  let res = await api.createCopySale(params);
        console.log(res)
         showModalInvoice(res.data.data)
        result()
  } catch (error) {
    setBackMsg(error.response.data.message)
  }
}

async function copyRefund(params) {
  try {
  let res = await api.createCopyRefund(params);
        console.log(res)
         showModalInvoice(res.data.data)
        result()
  } catch (error) {
    setBackMsg(error.response.data.message)
  }
}

// END API CALL FUNCTION

function setBackMsg(msg){
  if(msg === "invalid-buyer-id" 
  || msg === "errors-with-vsdc"
  || msg === "invalid-advance"){
    errMessage.value = t("app.main.backMessage."+msg)
  } else {
    errMessage.value = t("app.invoice-issue.error")
  }

}


function result(){
      resetValue();
      message.value = "app.create-invoice.success"
      setTimeout(() => {
        message.value = ""
      }, 4500);
}

function deleteInvoice() {
  // console.log("Delete");
  resetValue()
}
// reset
function resetValue(){
  billArticles.value = []
  errMessage.value = null
  paymentAndDataToolRef.value.resetField();
  ArticlesToolRef.value.resetCurrentArticle();
  store.setInvoiceAction(null)
  store.setInvoiceForAction(null)
}

// ARTICLES
function addArticle(article){
  errMessage.value = null
  billArticles.value.push(article);
}
function removeArticle(index){
  billArticles.value.splice(index, 1);
}
function removeAllArticle(){
  console.log('remove')
  billArticles.value = []
}
function editArticle(index, obj){
  // console.log(obj)
  // console.log(index)
  console.log(obj)
  billArticles.value[index] = obj
}

// PAYMENT
function getPayment (e) {
  console.log(e)
  paymentObj.value = e
  
}

// 
async function getInvoice( id ) {
  if(!clientsStore.currentClient)
  return
  
  let params = {
    cli_id:  clientsStore.currentClient.cli_id
  };
  try {
    let res = await api.getSingleInvoice(id, params);
    if (res.status === 200) {
      invoice.value = res.data.data;
      console.log(res.data.data.items)
      setOldArticle(res.data.data.items);
      store.setInvoiceForAction(null);
      // just for modal testing
      // showModalInvoice(res.data.data)
    }
  } catch (error) {
    console.error(error);
  }
}

function setOldArticle (items){
  //   name: "Svargla",
  //   gtin: "561329319123",
  //   quantity: 1,
  //   labels: ["Ж"],
  //   unitPrice: 351,
  //   art_price_total: "351.00",
  
  for(let i = 0; i < items.length; i++ ){
    billArticles.value.push({
      name: items[i].name,
      gtin: items[i].gtin,
      quantity: items[i].quantity,
      labels:  items[i].labels,
      unitPrice: items[i].unitPrice,
      art_price_total: items[i].totalPrice,
      amountPaid: store.getInvoiceAction === "complete-ADVANCE" || store.getInvoiceAction.startsWith ("refund") ? items[i].amountPaid : 0,
      // amountPaid: items[i].amountPaid,
      totalPaid: items[i].totalPaid


    })
  }
}

// labels -  tax
const getClientTaxLabels = async (special_tax = false) => {
  // if invoiceType === "ADVANCE"  special_tax = true
  invoiceType.value = special_tax
  if(special_tax === "ADVANCE"){
    console.log(taxStore.getSpecialTax)
    if(!taxStore.getSpecialTax) {
      await taxStore.setSpecialTax()
    }
    clientTaxLabels.value = taxStore.getSpecialTax
  } else {
    console.log(taxStore.getOrdinaryTax)
    if(!taxStore.getOrdinaryTax) {
      await taxStore.setOrdinaryTax()
    }
    clientTaxLabels.value = taxStore.getOrdinaryTax
  }
  // if(!clientsStore.currentClient)
  //   return

  // try {
  //   let params = {
  //     cli_id: clientsStore.currentClient.cli_id,
  //   };
  //   let res = null
  //   if (special_tax) {
  //     // res = await api.getAvansTaxLabels(params);
  //     params.special = "advance"
  //   } 
  //    res = await api.getTaxLabels(params);
  //   if (res.status === 200) {
  //     clientTaxLabels.value = res.data.data;
  //     console.log(clientTaxLabels.value)
  //   }
  // } catch (error) {
  //   console.error(error);
  // }
};

function showModalInvoice(invoice, refund = null){
  const data = {
    component: "invoiceModal",
    title:'',
    options: {
      data: invoice,
      refund: refund
    }
  }
  modalStore.setModalData(data)
}

//style
let isShaking = ref(false);
const handleShake = () => {
  isShaking.value = true;
  setTimeout(() => {
    isShaking.value = false;
  }, 1500);
};
watch(
  () => errMessage.value,
  () => {
    handleShake();
  }
);

</script>

<style lang="scss" scoped>
.add {
  padding: 5px 15px;
}
.notif-label {
  text-align: right;
  position: absolute;
  right: 10px;
  top: 0;
  top:42px;
  font-size: 13px;
  font-style: italic;
  color: red;
}
.sucess{
  color: #2bb498;
  width:100%;
  text-align:right;
  display:block;
  height:13px;
  margin-bottom: 15px;
}
.create-invoice-wrapper {
  height: 100%;
  padding-bottom: 100px;
  .create-invoice-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $main-dark;
    font-weight: 800;
    h2 {
      font-size: 40px;
    }
    .header-btns {
      position: relative;
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
        margin-right: $spacing-margine;
      }
      .delete {
        color: $main-green;
        cursor: pointer;
      }
    }
  }
  .content-wrap {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  .input-grp {
    display: flex;
    .input-wrap,
    .multiselect-wrap {
      // margin-right: $spacing-margine;
      max-width: unset;
    }
  }
}
</style>
