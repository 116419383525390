<template>
  <div
    v-if="router.currentRoute.value.path === '/item-overview'"
    class="component-wrap"
  >
    <div class="content-header">
      <div class="title">
        <h2>{{ $t("app.item-overview.title") }}</h2>
      </div>
    </div>
    <div v-if="itemList.length > 0" class="content-bar">
      <SearchInput :placeholder="$t(`app.item-overview.searchLabel`)" 
      @search="(val) => (searchByKey = val)"
      />
      <button v-if="!isMobile" class="btn new" @click="goToCreateItem">
        {{ $t("app.item-overview.newItemBtn") }}
      </button>
      <!-- <button v-if="!isMobile" class="btn new">placeholder</button> -->
      <div class="mob-btns">
        <button v-if="isMobile" class="btn round">
          <span class="material-symbols-rounded fat" @click="goToCreateItem">
            add
          </span>
        </button>
        <button v-if="isMobile" class="btn round">
          <span class="material-symbols-rounded fat">
            vertical_align_bottom
          </span>
        </button>
      </div>
    </div>
    <div v-if="itemList.length > 0" class="list-view">
      <div class="item-list-wrap">
        <div class="item-list-header">
          <span v-if="!isMobile" class="row-1">{{
            $t("app.item-overview.listHeaderNum").toUpperCase()
          }}</span>
          <span class="row-2">{{
            $t("app.item-overview.listHeaderName").toUpperCase()
          }}</span>
          <span class="row-3">{{
            $t("app.item-overview.listHeaderPrice").toUpperCase()
          }}</span>
          <span class="row-4">{{
            $t("app.item-overview.listHeaderGtin").toUpperCase()
          }}</span>
           <div class="btns"></div>
        </div>
        <div
          class="item-list-item"
          v-for="(n , index) in itemList"
          :key="index"
          :class="{ alt: index % 2 === 1 }"
        >
          <!-- class name lmao -->
          <div v-if="!isMobile" class="item-num">{{ n.art_id }}</div>
          <div class="item-name" :title="n.art_name"> {{ n.art_name }}</div>
          <div class="item-price">{{n.art_base_price}}</div>
          <div class="item-gtin">{{n.art_gtin}}</div>
          <div class="btns">
            <div class="edit-btn" @click="editItem(n.art_id, n)">
              <span class="material-symbols-rounded"> edit </span>
            </div>
            <div class="del-btn" @click="delItem(n)">
              <span class="material-symbols-rounded"> delete </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isMobile" class="invoice-img-wrap">
        <img src="../../assets/images/invoice-img.png" alt="" />
      </div>
    </div>
    <div v-if="itemList.length > 0"
       class="content-footer">
      <div class="pagination">
        <!--
        <Pagination :activePage="1" :pages="20" />
        -->
        <Pagination
          :activePage="page"
          :pages="pagesCount"
          @setActive="changePage"
        />
        <!-- TODO implement logic xd -->
      </div>
      <!-- <div class="total-results">
        {{ $t("app.item-overview.results", { current: "1-12", total: 200 }) }}
      </div> -->

      <div
      v-if="pagesCount" 
      class="total-results">
        {{
          $t("app.invoice-overview.results", {
            current: `${currentPagesStart} - ${currentPagesEnd}`,
            total: itemCount,
          })
        }}
      </div>

    </div>
      <div v-if="itemList.length <= 0" class="no-invoices">
      <div class="info-wrapper">
        <div class="create-new-text">
          {{ $t("app.item-overview.noArticle") }}
        </div>
        <button class="btn new" @click="goToCreateItem">
        {{ $t("app.item-overview.newItemBtn") }}
        </button>
      </div>
      <div class="img-wrapper">
        <img src="../../assets/images/no-invoice-found.png" alt="" />
      </div>
   
    </div>
  </div>
  <router-view v-else />
</template>

<script setup>
import api from "../../api/index.js";
import { useClientsStore } from "../../store/clientsStore";
import { useArticleStore } from "../../store/articleStore"
import { ModalStore } from "@/store/modalStore";

const modalStore = ModalStore();

// pagination
let pagCount = ref(12);
let page = ref(1);
let searchByKey = ref("");

//DATA
let itemList = ref([]);
let itemCount = ref(1);

const router = useRouter();
const route = useRoute();
let isMobile = inject("isMobile");

const goToCreateItem = () => {
  router.push("/item-overview/create-item");
};

// store
const clientStore = useClientsStore();
const articleStore = useArticleStore();

async function getArticles() {
  try {
    const params = {
      cli_id: clientStore.currentClient.cli_id,
      count: pagCount.value,
      offset: (page.value - 1) * pagCount.value,
      search: searchByKey.value,
    };
    const res = await api.getArticles(params);
    console.log(res.data.meta_info.count);
    itemList.value = res.data.data
    itemCount.value = res.data.meta_info.count
    
  } catch (error) {
    console.log(error);
  }
}
async function changePage(newPage) {
  if (newPage !== page.value) {
    page.value = newPage;
    getArticles();
  }
}

watch(
  [() => searchByKey.value , () => route.path],
  ([newSearch, newPath],[oldSearch, oldPath]) => {
    
    if(newSearch !== oldSearch){
      page.value = 1
      getArticles();
    }
    if(newPath !== oldPath) {
      getArticles();
    }
  }
);

onMounted(() => {
  getArticles();
});

const editItem = (id, article) => {
  articleStore.setArticleData(article)
  router.push("/item-overview/edit-item/"+id);
};

function delItem(item) {
  console.log(item)

  const data = {
    component: "confirmModal",
    title: "app.api.delete",
    options: {
      confirm: () => {
        confirmDelete(item.art_id);
      },
      name: item.art_name
    },
  };
  modalStore.setModalData(data);


}


const confirmDelete = async (id) => {
  try {
    const params = {
      id: id,
      cli_id: clientStore.currentClient.cli_id,
    };
    const res = await api.deleteArticle(params);
    console.log(res);
    // reset list
    page.value = 1
    getArticles()
  } catch (error) {
    console.log(error);
  }
};


const pagesCount = computed(() => {
  return Math.ceil(itemCount.value / pagCount.value);
});
const currentPagesStart = computed(() => {
  return page.value * pagCount.value - (pagCount.value - 1);
});
const currentPagesEnd = computed(() => {
  if (pagesCount.value === page.value) {
    console.log(itemCount.value);
    return itemCount.value;
  } else {
    return page.value * pagCount.value;
  }
});

</script>

<style lang="scss" scoped>
.content-bar {
  justify-content: space-between;
  .mob-btns {
    display: flex;
    button {
      margin-left: $spacing-margine;
    }
  }
}
.item-list-wrap {
  max-width: 100%;
  width: 100%;
  margin-bottom: 25px;
  .item-list-header,
  .item-list-item{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .item-list-header {
    padding: 0 33px;
    // display: flex;
    .row-1 {
      width: 110px;
    }
    .row-2 {
      width: 216px;
    }
    .row-3 {
      width: 174px;
    }
    .row-4 {
      width: 156px;
    }

    span {
      text-align: left;
      color: #acacad;
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
    }
  }
  .item-list-item {
    width: 100%;
    height: 50px;
    padding: 0 33px;
    border-radius: 50px;

    // display: flex;
    // align-items: center;
    // flex-direction: row;
    // justify-content: space-between;

    .item-num {
      width: 110px;
    }
    .item-name {
      width: 216px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .item-price {
      width: 174px;
    }
    .item-gtin {
      width: 156px;
    }

    div {
      // text-align: left;
      color: #3b4161;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }
    .item-num,
    .item-name {
      font-weight: 700;
    }
   
  }
  .alt {
    background-color: #f6f6f6;
  }
}
.btns {
  margin-left: 122px;
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.component-wrap {
  .content-footer {
     // width: 62.5%;
  }
}
.material-symbols-rounded {
  color: #888889;
  font-size: 12px;
  &:hover {
    cursor: pointer;
  }

  &.fat {
    color: #fff;
    height: $circle-btn-mobile-size;
    width: $circle-btn-mobile-size;
    border-radius: 50%;
    font-size: 30px;
    line-height: 38px;
    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
  }

  font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 24;
}

@media only screen and (max-width: 800px) {
  .component-wrap {
    .content-footer {
      width: 100%;
    }
  }
  .item-list-wrap {
    width: 100%;
    .item-list-header {
      padding: 0 20px;
      .row-2 {
        width: 88px;
      }
      .row-3 {
        width: 88px;
      }
      .row-4 {
        width: 90px;
        text-align: center;
      }
    }
    .item-list-item {
      padding: 0 20px;
      .item-num {
        width: 50px;
      }
      .item-name {
        width: 100px;
      }
      .item-price {
        width: 100px;
      }
      .item-gtin {
        width: 100px;
      }
     
    }
  }
   .btns {
        margin-left: 20px;
      }
}
</style>
