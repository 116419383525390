<template>
  <div class="auth-wrap">
    <div class="main-view">
      <div class="header">
        <div class="logo gap">e<span>Fiskalko</span></div>
        <div class="version">
          {{ $t("app.ver") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <h2>{{ $t("app.auth.loginPage.title") }}</h2>
        </div>
        <div class="inputs-wrap">
          <BaseInput
            v-model="email"
            @input="onInputEmail"
            :notification="emailNotif"
            class="mtb-20"
            :label="$t(`app.auth.loginPage.emailLabel`)"
          />
          <BaseInput
            v-model="password"
            @input="(value) => (password = value)"
            :notification="passwordNotif"
            class="mtb-20"
            :label="$t(`app.auth.loginPage.passwordLabel`)"
            :password="true"
          />
          <div class="forgor-password">
            <a href="/auth/forgot-password">{{
              $t("app.auth.loginPage.forgorPw")
            }}</a>
          </div>
        </div>
        <button @click="login" class="btn login" :disabled="isLoading">
          <BaseSpinner v-if="isLoading"></BaseSpinner>
          <span v-else>{{ $t("app.auth.loginPage.loginBtn") }}</span>
        </button>

        <div :class="{ shakeWarn: isErrorNotifShaking }" class="errmsg">
          {{ errorNotif }}
        </div>
      </div>
      <div class="copyright">
        {{ $t("app.copyrights") }}
      </div>
    </div>
    <div class="info-bar">
      <div class="info-text-wrap">
        <div class="info-title">
          <h2>{{ $t("app.auth.loginPage.sidebarTitle") }}</h2>
        </div>
        <div class="info-text">
          {{$t('app.main.loginTxt')}}
        </div>
        <button class="btn white" @click="goToRegister">
          {{ $t("app.auth.loginPage.sidebarBtn") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import api from "../../../api/index";
import { validateEmail } from "@/assets/js/helpers";
import { useI18n } from "vue-i18n";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useUserStore } from "@/store/userStore";
import { useClientsStore } from "@/store/clientsStore";

const { t } = useI18n();
const router = useRouter();
const recaptcha = useReCaptcha();
const userStore = useUserStore();
const clientsStore = useClientsStore();
let email = ref("");
let password = ref("");

//Errors
let emailNotif = ref("");
let passwordNotif = ref("");
let errorNotif = ref("");
let isErrorNotifShaking = ref(false);

let isLoading = ref(false);

const onInputEmail = (value) => (email.value = value);

const checkForm = () => {
  let isValid = true;
  emailNotif.value = "";
  passwordNotif.value = "";
  if (!validateEmail(email.value)) {
    isValid = false;
    emailNotif.value = emailInvalidMsg.value;
  }
  if (!email.value) {
    isValid = false;
    emailNotif.value = emailRequiredMsg.value;
  }
  if (
    !password.value ||
    password.value === null ||
    password.value === undefined
  ) {
    isValid = false;
    passwordNotif.value = passwordRequiredMsg.value;
  }
  return isValid;
};

const login = async () => {
  if (checkForm()) {
    try {
      isLoading.value = true;
      errorNotif.value = "";
      await recaptcha?.recaptchaLoaded();
      const token = await recaptcha?.executeRecaptcha("login");
      let params = {
        email: email.value,
        password: password.value,
        token: token,
      };
      const res = await api.loginApi(params);

      if (res.status === 200) {
        userStore.sid = res.data.sid;
        userStore.id = res.data.id;
        userStore.name = res.data.name;
        userStore.isLoggedIn = true;

        localStorage.setItem("sid", res.data.sid);
        await clientsStore.fetchClients();
        if(clientsStore.currentClient){
          gotToHome()
        } else{
          router.push("/single-company");
        }
      }
    } catch (error) {
      let msg = error.response.data.message;
      if (msg === "invalid-credentials") {
        errorNotif.value = badCredentialsMsg.value;
      } else if (msg === "verify") {
        errorNotif.value = verifyRequiredMsg.value;
      } else {
        errorNotif.value = tryAgainLaterMsg.value;
      }
      console.error(error.response.data.message);
    } finally {
      isLoading.value = false;
    }
  }
};

const goToRegister = () => {
  router.push("/auth/register");
};

const gotToHome = () => {
  router.push("/");
};

const handleShake = () => {
  isErrorNotifShaking.value = true;
  setTimeout(() => {
    isErrorNotifShaking.value = false;
  }, 1500);
};

watch(errorNotif, () => {
  handleShake();
});

const emailInvalidMsg = computed(() =>
  t("app.auth.loginPage.form.emailInvalid")
);
const emailRequiredMsg = computed(() =>
  t("app.auth.loginPage.form.emailRequired")
);
const passwordRequiredMsg = computed(() =>
  t("app.auth.loginPage.form.passwordRequired")
);

const badCredentialsMsg = computed(() =>
  t("app.auth.loginPage.form.badCredentials")
);
const verifyRequiredMsg = computed(() =>
  t("app.auth.loginPage.form.verifyRequired")
);
const tryAgainLaterMsg = computed(() =>
  t("app.auth.loginPage.form.tryAgainLater")
);

onMounted(() => {
  addEventListener("keydown", (event) => {
    if (event.key === "Enter") {
      login();
    }
  });
});
onUnmounted(() => {
  removeEventListener("keydown", (event) => {
    if (event.key === "Enter") {
      login();
    }
  });
});
</script>

<style lang="scss" scoped>
.gap {
  margin-right: 28px;
}
.forgor-password {
  margin-top: -14px;
  margin-bottom: 20px;
  width: 546px;
  display: flex;
  justify-content: flex-end;
  a {
    color: $main-dark;
    font-style: italic;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-decoration: none;
  }
}
.white {
  width: 250px;
  height: 50px;
  background-color: #fff;
  color: $main-dark;
}
.errmsg {
  height: 30px;
  margin-top: 30px;
  color: red;
}
@media only screen and (max-width: 800px) {
  .forgor-password {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 0;
    a {
      font-size: 13px;
      line-height: 15px;
    }
  }

  .white {
    width: 162px;
    height: 40px;
  }
}
</style>
